import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Text, ClickableDiv } from '../../atoms';
import { AlignEnum, ColorNameEnum, WeightEnum } from '../../types';

import './ResultCard.less';

export interface ResultCardProps {
  className?: string;
  resultMainText?: string;
  resultMainIcon?: JSX.Element;
  resultTopBadges?: JSX.Element;
  resultSecondaryText?: string;
  primaryActionBtn?: JSX.Element;
  secondaryActionBtn?: JSX.Element;
  onClick?: (payload?: any) => void;
  resultImg?: JSX.Element;
  showLocation?: boolean;
  verticalMode?: boolean;
  resultLeftDetail?: JSX.Element;
  secondaryResultLeftDetail?: JSX.Element;
  addressTitle?: string | null;
  address?: string;
  addressColor?: ColorNameEnum;
  locationDistance?: JSX.Element;
  shareModal?: JSX.Element;
  showNewBookButton?: boolean;
  customResultActions?: boolean;
  showBottomActionBanner?: boolean;
  horizontalActionButtons?: boolean;
  resultBottomActionBanner?: JSX.Element;
  showShadow?: boolean;
  id?: string;
  sideBannerMode?: boolean;
}

const ResultCard: FunctionComponent<ResultCardProps> = ({
  children,
  className,
  resultMainText,
  resultMainIcon,
  resultTopBadges,
  resultSecondaryText,
  primaryActionBtn,
  secondaryActionBtn,
  onClick,
  resultImg,
  showLocation = false,
  verticalMode = true,
  resultLeftDetail,
  secondaryResultLeftDetail,
  addressTitle,
  address,
  addressColor,
  locationDistance,
  showNewBookButton = false,
  customResultActions = false,
  showBottomActionBanner = false,
  horizontalActionButtons = false,
  resultBottomActionBanner,
  showShadow = true,
  id,
  sideBannerMode = false,
  shareModal,
}) => (
  <div className="result-wrap" id={id ? `result-card-${id}` : undefined}>
    {shareModal}
    <ClickableDiv
      className={classNames(
        'card result-card',
        { 'vertical-mode': verticalMode },
        { 'bottom-banner-opened': showBottomActionBanner },
        { 'no-shadow': !showShadow },
        { 'side-banner-mode': sideBannerMode },
        className,
      )}
      clickHandler={onClick}
      disabled={!onClick}
    >
      <div className="result-main-content">
        <div className="result-left">
          <div className="result-img">{resultImg}</div>
          {resultLeftDetail && <div className="network-status">{resultLeftDetail}</div>}
          {secondaryResultLeftDetail && <div>{secondaryResultLeftDetail}</div>}
        </div>
        <div className="result-middle">
          <div className="result-main">
            <Text
              align={AlignEnum.left}
              weight={WeightEnum.semibold}
              colorName={ColorNameEnum.ultraDark}
              className="main-text"
            >
              {resultMainText}
              {resultMainIcon}
              {resultTopBadges}
            </Text>
            <Text
              align={AlignEnum.left}
              colorName={ColorNameEnum.ultraDark}
              weight={WeightEnum.bold}
              className="speciality-text"
            >
              {resultSecondaryText}
            </Text>
            {showLocation && (
              <div>
                <div className="result-location">
                  {addressTitle && (
                    <Text
                      align={AlignEnum.left}
                      weight={WeightEnum.bold}
                      className="address-title"
                      colorName={addressColor}
                    >
                      {addressTitle},&nbsp;
                    </Text>
                  )}
                  <Text
                    className="location-address"
                    align={AlignEnum.left}
                    colorName={addressColor}
                  >
                    <div>{address}</div>
                    {locationDistance && (
                      <div className="location-distance">{locationDistance}</div>
                    )}
                  </Text>
                </div>
              </div>
            )}
          </div>

          <div className="result-details">{children}</div>
        </div>

        <div
          className={classNames(
            { 'result-actions': !customResultActions },
            { 'custom-result-actions': customResultActions },
            { 'new-book-button': showNewBookButton },
            { horizontal: horizontalActionButtons },
          )}
        >
          {primaryActionBtn && <div className="top-item">{primaryActionBtn}</div>}
          {secondaryActionBtn && <div className="bottom-item">{secondaryActionBtn}</div>}
        </div>
      </div>

      <div className="result-bottom-action-banner">
        <div className="banner-content">{resultBottomActionBanner}</div>
      </div>
    </ClickableDiv>
    <div className="card-backdrop" />
  </div>
);

export default ResultCard;
