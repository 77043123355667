import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { Button, ButtonProps, InputStyleWrapper, InputStyle } from '../../atoms';
import SelectInput, { DefaultOptionType } from '../select-input';
import './buttonGroup.less';

const DropTypesOptionsPropTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

type DropdownType = { value: DefaultOptionType; id: string };
type DropdownOptionsType = InferProps<typeof DropTypesOptionsPropTypes>;

const mapDropdownOptions = (dropdownOptions: DropdownOptionsType[]): DefaultOptionType[] =>
  dropdownOptions.map(({ value, text }) => ({ value, label: text }));

const ItemPropTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dropDownOptions: PropTypes.arrayOf(PropTypes.shape(DropTypesOptionsPropTypes).isRequired),
  selectClassName: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export const ButtonGroupPropTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropTypes).isRequired).isRequired,
  theme: PropTypes.shape({
    secondaryDarkColor: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    linkColor: PropTypes.string.isRequired,
    mainColor: PropTypes.string.isRequired,
  }).isRequired,
  selectPlaceholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column', 'default']),
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
};

export type ButtonGroupProps = typeof ButtonGroupPropTypes &
  Partial<Pick<ButtonProps, 'styleOverrides' | 'buttonType' | 'color' | 'onChange'>>;

function ButtonGroup({
  items,
  value,
  className,
  itemClassName,
  direction,
  selectPlaceholder,
  onChange,
  theme,
  searchable,
  searchPlaceholder,
  ...rest
}: ButtonGroupProps) {
  const [openedDropdownId, setOpenedDropdownId] = useState<string | null>(null);
  const [dropdownValue, setDropdownValue] = useState<DropdownType | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredItems = useMemo(() => {
    const normalizedSearchTerm = searchTerm.trim().toLowerCase();
    if (!searchable || searchTerm === '') return items;

    return items.filter(({ text }) => text.toLowerCase().includes(normalizedSearchTerm));
  }, [items, searchable, searchTerm]);

  useEffect(() => {
    const selectedItem = items.find((currItem) => currItem.value === value);
    if (selectedItem && !selectedItem.dropDownOptions) setOpenedDropdownId(null);
  }, [items, value]);

  const clickHandler = (
    e: React.MouseEvent,
    option: number | string,
    onClick: Function | null | undefined,
  ) => {
    if (onClick) onClick(option);
    e.preventDefault();
    if (onChange) onChange(option);
    setOpenedDropdownId(null);
  };

  const selectHandler = (
    dropdownOption: DefaultOptionType | null | undefined,
    buttonOption: string,
  ) => {
    setDropdownValue(dropdownOption ? { value: dropdownOption, id: buttonOption } : null);
    if (onChange && dropdownOption) onChange(dropdownOption.value);
  };

  return (
    <div>
      {searchable && (
        <InputStyleWrapper inputStyle={InputStyle.pill} leftIcon={<i className="icon-search-2" />}>
          <div className="input">
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              placeholder={searchPlaceholder || ''}
              type="text"
            />
          </div>
        </InputStyleWrapper>
      )}
      <div
        data-id="button-group"
        className={classNames('icon-list', className, {
          'column-list': direction === 'column',
        })}
        role="radiogroup"
      >
        {filteredItems.map((item) => {
          const selected = value === item.value;

          return openedDropdownId === item.value && item.dropDownOptions ? (
            <InputStyleWrapper>
              <SelectInput
                key={item.value}
                placeholder={selectPlaceholder}
                className={classNames(itemClassName, item.selectClassName)}
                options={mapDropdownOptions(item.dropDownOptions)}
                value={
                  dropdownValue?.id === openedDropdownId ? dropdownValue.value.value : undefined
                }
                onChange={(dropdownOption: any) => {
                  selectHandler(dropdownOption ? dropdownOption[0] : null, item.value);
                }}
                theme={theme}
              />
            </InputStyleWrapper>
          ) : (
            <Button
              role="radio"
              key={item.value}
              className={classNames(item.value, itemClassName, { buttonGroupSelected: selected })}
              onClick={
                item.dropDownOptions
                  ? () => setOpenedDropdownId(item.value)
                  : (e) => clickHandler(e, item.value, item.onClick)
              }
              buttonType="link"
              value={item.value}
              selected={selected}
              {...rest}
            >
              {item.icon && <i className={`item-icon ${item.icon}`} />}
              <div>{item.text}</div>
            </Button>
          );
        })}
      </div>
    </div>
  );
}

ButtonGroup.propTypes = ButtonGroupPropTypes;

ButtonGroup.defaultProps = {
  value: undefined,
  className: undefined,
  direction: 'default',
  selectPlaceholder: '',
  onChange: () => {},
  searchPlaceholder: undefined,
};

export default ButtonGroup;
