import { Decoder, TypeOf, brand, Branded, number, string } from 'io-ts';
import { isLeft } from 'fp-ts/lib/Either';
import schemaErrorReporter from 'io-ts-reporters';
import config from '../config';

export function assertType<T>(input: any, validator: Decoder<any, T>): asserts input is T {
  const validation = validator.decode(input);
  if (isLeft(validation)) {
    // Don't throw error if this is a prod env, the client should continue as if everything's ok
    if (config.VIM_CLIENT_LOGGER) {
      throw new Error(schemaErrorReporter.report(validation).join('\n'));
    }
  }
}

interface NonEmptyStringBrand {
  readonly NonEmptyString: unique symbol;
}
export const NonEmptyStringValidator = brand(
  string,
  (str): str is Branded<string, NonEmptyStringBrand> => str?.length > 0,
  'NonEmptyString',
);
export type NonEmptyString = TypeOf<typeof NonEmptyStringValidator>;

interface NumberNotNaNBrand {
  readonly NumberNotNaN: unique symbol;
}
export const NumberNotNaNValidator = brand(
  number,
  (num): num is Branded<number, NumberNotNaNBrand> => !Number.isNaN(num),
  'NumberNotNaN',
);
export type NumberNotNaN = TypeOf<typeof NumberNotNaNValidator>;
