import React, { FunctionComponent, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from '@getvim/translate';
import { Button, CheckboxInput, deprecated } from '@getvim/atomic-ui';
import useVerticalMode from '@getvim/components-hooks-use-vertical-mode';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { FiltersType, SearchFiltersType } from './typesDef';
import FiltersModal from './modal/FiltersModal';
import SearchEvents from '../../utils/searchEvents';
import { getAllLanguages } from '../../api';

import './FiltersMenu.less';

const SMALL_SCREEN_WIDTH = 768;

const FiltersMenu: FunctionComponent<SearchFiltersType> = ({
  filters,
  userLanguage,
  onChange,
  onReset,
  enableReset = true,
  shouldDisableDistanceFilterForVirtualProviders = false,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const isVerticalMode = useVerticalMode(SMALL_SCREEN_WIDTH);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isDistanceTouched, setIsDistanceTouched] = useState(false);
  const { distance, spokenLanguage, gender, sanitasPlus, offerTelemedicine } = filters;

  const [languageOptions, setlanguageOptions] = useState<deprecated.FilterOptions>();

  useEffect(() => {
    getAllLanguages().then(setlanguageOptions);
  }, []);

  if (isVerticalMode) {
    return (
      <div className="filters-widget" style={{ backgroundColor: theme.mainColor }}>
        <div className="filter-item">
          <Button
            color="white"
            className="filters-btn"
            buttonType="dropdown"
            onClick={() => setIsFiltersOpen(true)}
          >
            <FormattedMessage id="general.filters" />
          </Button>
          <FiltersModal
            shouldDisableDistanceFilterForVirtualProviders={
              shouldDisableDistanceFilterForVirtualProviders
            }
            userLanguage={userLanguage}
            languageOptions={languageOptions}
            filters={filters}
            isOpen={isFiltersOpen}
            onReset={() => {
              onReset();
              setIsFiltersOpen(false);
              setIsDistanceTouched(false);
            }}
            onClose={() => setIsFiltersOpen(false)}
            onChange={(modalFilters: Partial<FiltersType>) => {
              onChange(modalFilters, (queryId, memberSessionId, brokerId) => {
                const updatedFilterKeys = Object.keys(modalFilters) as Array<keyof FiltersType>;
                return updatedFilterKeys.map((updatedFilterKey) =>
                  SearchEvents.filterClick({
                    filterName: updatedFilterKey,
                    filterValue: modalFilters[updatedFilterKey],
                    memberSessionId,
                    queryId,
                    brokerId,
                  }),
                );
              });
              setIsFiltersOpen(false);
              if (modalFilters.distance) {
                setIsDistanceTouched(true);
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="filters-widget" style={{ backgroundColor: theme.mainColor }}>
      <div className="filter-item">
        <deprecated.DistancePopover
          disabled={shouldDisableDistanceFilterForVirtualProviders}
          value={distance}
          theme={theme}
          isTouched={isDistanceTouched}
          onChange={(selectedDistance: number) => {
            setIsDistanceTouched(true);
            onChange({ distance: selectedDistance }, (queryId, memberSessionId, brokerId) => {
              return [
                SearchEvents.filterClick({
                  filterName: 'distance',
                  filterValue: selectedDistance,
                  memberSessionId,
                  queryId,
                  brokerId,
                }),
              ];
            });
          }}
        />
      </div>

      <div className="filter-item">
        <deprecated.GenderPopover
          btnType="dropdown"
          theme={theme}
          onChange={(selectedGender: deprecated.GenderEnum) => {
            onChange({ gender: selectedGender }, (queryId, memberSessionId, brokerId) => {
              return [
                SearchEvents.filterClick({
                  filterName: 'gender',
                  filterValue: selectedGender,
                  memberSessionId,
                  queryId,
                  brokerId,
                }),
              ];
            });
          }}
          value={gender}
        />
      </div>

      <div className="filter-item">
        <deprecated.LanguagePopover
          theme={theme}
          options={languageOptions}
          onChange={(selectedSpokenLanguages: deprecated.LangEnum) => {
            onChange(
              { spokenLanguage: selectedSpokenLanguages },
              (queryId, memberSessionId, brokerId) => {
                return [
                  SearchEvents.filterClick({
                    filterName: 'spokenLanguage',
                    filterValue: selectedSpokenLanguages,
                    memberSessionId,
                    queryId,
                    brokerId,
                  }),
                ];
              },
            );
          }}
          value={spokenLanguage}
          btnType="dropdown"
        />
      </div>

      <div className="filter-item">
        <CheckboxInput
          className="trans-checkbox nowrap"
          id="offerTelemedicine"
          checked={offerTelemedicine}
          label={intl.formatMessage({ id: 'components.filters.offersVirtualVisits' })}
          onChange={(value: any) => {
            onChange(
              { offerTelemedicine: value.currentTarget.checked },
              (queryId, memberSessionId, brokerId) => {
                return [
                  SearchEvents.filterClick({
                    filterName: 'offerTelemedicine',
                    filterValue: value.currentTarget.checked,
                    memberSessionId,
                    queryId,
                    brokerId,
                  }),
                ];
              },
            );
          }}
        />
      </div>

      <div className="filter-item">
        <CheckboxInput
          className="trans-checkbox nowrap"
          id="sanitasPlus"
          checked={sanitasPlus}
          label={intl.formatMessage({ id: 'components.filters.medicareLabel' })}
          onChange={(value: any) => {
            onChange(
              { sanitasPlus: value.currentTarget.checked },
              (queryId, memberSessionId, brokerId) => {
                return [
                  SearchEvents.filterClick({
                    filterName: 'sanitasPlus',
                    filterValue: value.currentTarget.checked,
                    memberSessionId,
                    queryId,
                    brokerId,
                  }),
                ];
              },
            );
          }}
        />
      </div>

      {enableReset ? (
        <div className="clear-filter-btn">
          <Button
            buttonType="link"
            color="white"
            onClick={() => {
              setIsDistanceTouched(false);
              onReset();
            }}
            data-id="reset-filters"
          >
            <i className="icon-x" />
            <FormattedMessage id="components.filters.clearFilters" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default FiltersMenu;
