import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.less';

const headerProps = {
  className: PropTypes.string,
  leftElement: PropTypes.node,
  rightElement: PropTypes.node,
  mobileMenu: PropTypes.node,
  userMenu: PropTypes.node,
  mobileSearchInput: PropTypes.node,
};

type HeaderPropsTypes = PropTypes.InferProps<typeof headerProps>;

const Header: FunctionComponent<HeaderPropsTypes> = ({
  children,
  leftElement,
  rightElement,
  className,
  mobileMenu,
  userMenu,
  mobileSearchInput,
}) => (
  <header className={classNames('header', className)}>
    {mobileMenu}
    {mobileSearchInput}
    {leftElement && <div className="header-left">{leftElement}</div>}
    <div className="header-middle">{children}</div>
    {rightElement && <div className="header-right">{rightElement}</div>}
    {userMenu}
  </header>
);

Header.propTypes = headerProps;

Header.defaultProps = {
  className: undefined,
  leftElement: undefined,
  rightElement: undefined,
};

export default Header;
