import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { getNameByPath } from '../models/pages';
import { getSessionIdIfExists } from '../api/tokensStorage';
import { getBrokerId } from '../utils/brokerIdStorage';
import { withAnalyticsProp } from '../utils/analytics';

export default function RouteWithAnalytics({
  analytics,
  children,
  ...otherProps
}: RouteProps & withAnalyticsProp) {
  useEffect(() => {
    if (!Array.isArray(otherProps.path)) {
      const pageName = getNameByPath(otherProps.path);
      if (pageName) {
        analytics.track(`Viewed ${pageName} Page`, {
          memberSessionId: getSessionIdIfExists(),
          brokerId: getBrokerId(),
        });
      } else {
        analytics.track(`Viewed Unknown Page`, { path: otherProps.path });
      }
    }
  }, [analytics, otherProps.path]);

  return <Route {...otherProps}>{children}</Route>;
}
