import React, { FunctionComponent } from 'react';
import { Popover as BasePopover, PopoverDisclosure, PopoverArrow, usePopoverState } from 'reakit';
import type { PopoverStateReturn } from 'reakit';
import classNames from 'classnames';
import './index.less';

interface CustomPopoverProps {
  popover: PopoverStateReturn;
  withArrow?: boolean;
}

const Popover: FunctionComponent<CustomPopoverProps & any> = ({
  popover,
  disclosure,
  className,
  children,
  withArrow,
  ...props
}) => (
  <>
    {disclosure && (
      <PopoverDisclosure {...popover} ref={disclosure.ref} {...disclosure.props}>
        {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
      </PopoverDisclosure>
    )}
    <BasePopover
      unstable_autoFocusOnHide={false}
      {...popover}
      {...props}
      className={classNames('reakit-popover', className)}
    >
      {withArrow && <PopoverArrow {...popover} className="popover-arrow" />}
      {children}
    </BasePopover>
  </>
);

export { usePopoverState, PopoverStateReturn };

export default Popover;
