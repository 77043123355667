import { intersection, string, type, TypeOf, partial } from 'io-ts';
import { NumberNotNaNValidator } from './utils';

export const GeoCoordinatesValidator = type(
  {
    latitude: NumberNotNaNValidator,
    longitude: NumberNotNaNValidator,
  },
  'GeoCoordinates',
);
export type GeoCoordinates = TypeOf<typeof GeoCoordinatesValidator>;

export const geoCodeSchema = partial({
  geocode: GeoCoordinatesValidator,
});
export const addressSchema = partial({
  address: string,
});
export const zipSchema = partial({
  zip: string,
});
export const GeoCodeValidator = intersection([geoCodeSchema, addressSchema, zipSchema]);
export type GeoCode = TypeOf<typeof GeoCodeValidator>;
