const Pages = {
  Landing: { path: '/landing', name: 'Landing' },
  MainSearch: { path: '/searchResults', name: 'Main Search' },
  ProviderDetails: { path: '/providerDetails', name: 'Provider Details' },
  ProviderNotFound: { path: '/providerDetails/notFound', name: 'Provider Not Found' },
  ErrorPage: { path: '/error', name: 'Error' },
};

export type PageType = keyof typeof Pages;

export function getNameByPath(path?: string) {
  return Object.values(Pages).find(({ path: currPath }) => currPath === path)?.name;
}

export default Pages;
