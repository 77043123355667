import { GeoCode } from '../../models/Geocode';
import { FreeTextTerm } from '../../models/FreeText';

export type FormDefTypes = {
  freeText: FreeTextTerm;
  geo: Partial<GeoCode>;
  queryId?: string;
};

export const defaultFormValues = ({ freeText, geo }: FormDefTypes): FormDefTypes => ({
  freeText,
  geo,
});
