import React, { PropsWithChildren, useState, useEffect } from 'react';
import { TranslateProvider, Locales } from '@getvim/translate';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { Loader } from '@getvim/atomic-ui';
import useSdkEvents, { initPreEventCallback } from '@getvim/components-hooks-use-sdk-events';
import AuthProvider from './AuthProvider';
import ThemeProvider from './ThemeProvider';
import translations from '../translations';
import {
  useApiKeyFromQSOnMount,
  getApiKey,
  saveApiKey,
  clearSessionId,
} from '../api/tokensStorage';
import useSchedulingSdk from '../hooks/useSchedulingSdk';
import { sendLogToBackend } from '../api/sendLogsToBackend';
import usePageNav from '../hooks/usePageNav';
import redirectToErrorPage from '../utils/redirectToErrorPage';
import { NonEmptyString } from '../models/utils';
import reactNativeEvent from '../utils/reactNativeEvent';

function getMessageSafe(message: string) {
  try {
    return JSON.parse(message);
  } catch (e) {
    return message;
  }
}

export default function CoreProviders({ children }: PropsWithChildren<any>) {
  const navToPage = usePageNav();
  const [isApiKeyReady, setIsApiKeyReady] = useState(false);
  const { user, apiKey } = useQueryString();
  const language = (user as any)?.language;

  useApiKeyFromQSOnMount(setIsApiKeyReady);
  useSchedulingSdk(isApiKeyReady);

  initPreEventCallback({
    closed: () => clearSessionId(),
  });

  function processPayload(payload: any) {
    const currApikey = getApiKey();

    if (!currApikey) {
      if (payload?.apiKey) {
        saveApiKey(payload.apiKey);
      } else {
        sendLogToBackend({ location: `App.tsx:AppWithUser:useSdkEvents`, payload, currApikey });
      }
    }

    if (payload?.user) {
      const { analyticsMetadata: metadataFromSdk } = payload;

      if (payload.user.language) {
        navToPage({
          page: 'Landing',
          params: {
            user: payload?.user,
            apiKey,
            analyticsMetadata: metadataFromSdk,
            memberToken: payload?.memberToken,
          },
        });
      } else {
        redirectToErrorPage(apiKey as any, metadataFromSdk as object);
      }
    }
  }

  const [shouldUseReactNativeEvents, isShouldUseReactNativeEventsLoading] = useFeatureFlag({
    flagName: 'scheduling.sanitasShouldUseReactNativeEvents',
    defaultValue: false,
  });

  useEffect(() => {
    const isReactNative =
      !isShouldUseReactNativeEventsLoading &&
      shouldUseReactNativeEvents &&
      window?.ReactNativeWebView;
    const onMessage = (message: MessageEvent) => {
      const { data } = message;
      const msg = getMessageSafe(data);
      if (msg || msg?.event) {
        const { payload } = msg;
        // somewhy message from webView.postMessage from ReactNative app have isTrusted false
        // and useSdkEvents don't work for it
        if (msg.event === 'onUpdateParams' && payload) {
          processPayload(payload);
        }
      }
    };

    if (isReactNative) {
      window.addEventListener('message', onMessage);
      reactNativeEvent({ event: 'load' });
    }
    return () => {
      if (isReactNative) {
        window.removeEventListener('message', onMessage);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShouldUseReactNativeEventsLoading, shouldUseReactNativeEvents]);

  useEffect(() => {
    if (apiKey) {
      saveApiKey(apiKey as NonEmptyString);
    } else {
      const location = `AppWithUser`;
      sendLogToBackend({ location, apiKey });
    }
  }, [apiKey]);

  useSdkEvents((payload) => {
    processPayload(payload);
  });

  return isApiKeyReady && (user as any)?.language ? (
    <TranslateProvider
      locale={Locales[(language?.toLocaleLowerCase() as Locales) || Locales.en]}
      rootTranslations={translations}
    >
      <AuthProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </AuthProvider>
    </TranslateProvider>
  ) : (
    <Loader />
  );
}
