/// <reference types="vite/client" />
import 'fast-text-encoding/text';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import CoreProviders from './core/CoreProviders';
import Routes from './routes/Routes';
import loadAccessibility from './accessibility';
import config from './config';

loadAccessibility();

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <FeatureFlagProvider rolloutKey={config.rollout__key}>
        <CoreProviders>
          <Routes />
        </CoreProviders>
      </FeatureFlagProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root'),
);
