import { defineMessages } from 'react-intl';

const messages = defineMessages({
  distanceButtonLabel: {
    defaultMessage: 'Distance',
    id: 'filters.distanceButtonLabel',
  },
  genderButtonLabel: {
    defaultMessage: 'Gender',
    id: 'filters.genderButtonLabel',
  },
  languageButtonLabel: {
    defaultMessage: 'Language',
    id: 'filters.languageButtonLabel',
  },
  familyPhysicianButtonLabel: {
    defaultMessage: 'Family Physician',
    id: 'filters.familyPhysicianButtonLabel',
  },
  categoryButtonLabel: {
    defaultMessage: 'Service / Procedure',
    id: 'filters.categoryButtonLabel',
  },
  distanceFilterSelected: {
    defaultMessage: 'Distance filter {distance} miles selected',
    id: 'filters.distanceFilterSelected',
  },
  genderFilterSelected: {
    defaultMessage: 'Gender filter {gender} selected',
    id: 'filters.genderFilterSelected',
  },
  languageFilterSelected: {
    defaultMessage: 'Language filter {language} selected',
    id: 'filters.languageFilterSelected',
  },
  familyPhysicianFilterSelected: {
    defaultMessage: 'Family Physician filter {familyPhysician} selected',
    id: 'filters.familyPhysicianFilterSelected',
  },
  categoryFilterSelected: {
    defaultMessage: 'Category filter {category} selected',
    id: 'filters.categoryFilterSelected',
  },
  any: {
    id: 'filters.any',
    defaultMessage: 'Any',
  },
});

export default messages;
