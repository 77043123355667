import React from 'react';
import { defineMessages, IntlShape } from 'react-intl';
import { useTranslation } from '@getvim/translate';
import ButtonGroup, { ButtonGroupPropTypes, ButtonGroupProps } from '../../buttonGroup';
import es from '../translations/es.json';

export type GenderEnum = 'MALE' | 'FEMALE' | 'ANY';

const GenderMessages = defineMessages({
  male: {
    defaultMessage: 'Male',
    id: 'gender.male',
  },
  female: {
    defaultMessage: 'Female',
    id: 'gender.female',
  },
  any: {
    defaultMessage: 'Any',
    id: 'gender.any',
  },
});

const genderToMessage = {
  MALE: GenderMessages.male,
  FEMALE: GenderMessages.female,
  ANY: GenderMessages.any,
};

function getGenderOptions(intl: IntlShape) {
  return [
    {
      value: 'ANY',
      text: intl.formatMessage(genderToMessage.ANY),
    },
    {
      value: 'MALE',
      text: intl.formatMessage(genderToMessage.MALE),
    },
    {
      value: 'FEMALE',
      text: intl.formatMessage(genderToMessage.FEMALE),
    },
  ];
}

type GenderSelectorProps = Omit<ButtonGroupProps, 'items' | 'value'> & { value: string };

// eslint-disable-next-line no-unused-vars
const { items, ...PropTypes } = ButtonGroupPropTypes;

function GenderSelector(props: GenderSelectorProps) {
  const intl = useTranslation({ es });
  const genderOptions = getGenderOptions(intl);
  return <ButtonGroup items={genderOptions} {...props} />;
}

GenderSelector.propTypes = PropTypes;

export function getTranslatedGender(gender: 'MALE' | 'FEMALE' | 'ANY', intl) {
  const genderMessage = genderToMessage[gender];
  return intl.formatMessage(genderMessage);
}

export default GenderSelector;
