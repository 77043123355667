import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useQueryString from '@getvim/components-hooks-use-query-string';
import SomethingWentWrongError from '../pages/errorPages/SomethingWentWrongError';
import Pages from '../models/pages';
import RouteWithAnalytics from './RouteWithAnalytics';
import useApiKeyPreferences from '../hooks/useApiKeyPreferences';
import App from '../App';

import 'bootstrap/less/bootstrap.less';
import '@getvim/atomic-ui/assets/styles/main.less';
import { getAnalytics } from '../utils/analytics';

export default () => {
  const { clientId } = useApiKeyPreferences();
  const { analyticsMetadata } = useQueryString();
  const metadata = analyticsMetadata as object;
  const analytics = getAnalytics({ clientId, metadata });

  return (
    <Switch>
      <RouteWithAnalytics path={Pages.ErrorPage.path} analytics={analytics}>
        <SomethingWentWrongError />
      </RouteWithAnalytics>
      <Route path="/">
        <App />
      </Route>
    </Switch>
  );
};
