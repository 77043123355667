import { AnalyticsClient } from './analyticsClient';

export const SdkBookButtonClicked = 'SDK book button clicked';

export type withAnalyticsProp = { analytics: AnalyticsClient };

export type EventCreatorType = (
  queryId?: string | null,
  memberSessionId?: string | null,
  brokerId?: string | null,
) => { eventName: string; params: Record<string, any> }[];

export type EventResult = { eventName: string; params: Record<string, any> };

export type BaseSearchEvent = {
  memberSessionId?: string | null;
  queryId?: string | null;
  brokerId?: string | null;
};
