import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { defineMessages, IntlShape } from 'react-intl';
import { useTranslation } from '@getvim/translate';
import Button, { ButtonProps } from '../button';
import es from './translations/es.json';
import { BookingType } from '../../types';

const buttonTextMessages = defineMessages({
  schedule: {
    defaultMessage: 'Schedule',
    id: 'bookButton.scheduleText',
  },
  book: {
    defaultMessage: 'Book',
    id: 'bookButton.bookText',
  },
  request: {
    defaultMessage: 'Request appointment',
    id: 'bookButton.requestText',
  },
});

const BookButtonProps = {
  bookingType: PropTypes.oneOf(Object.values(BookingType)).isRequired,
  btnClassName: PropTypes.string,
  scheduleButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const getButtonText = (bookingType: BookingType, scheduleButton: boolean, intl: IntlShape) => {
  if (bookingType === BookingType.CONCIERGE)
    return scheduleButton
      ? intl.formatMessage(buttonTextMessages.schedule)
      : intl.formatMessage(buttonTextMessages.request);
  if (bookingType === BookingType.HYBRID || bookingType === BookingType.REQUEST)
    return intl.formatMessage(buttonTextMessages.request);
  return intl.formatMessage(buttonTextMessages.book);
};
export type BookButtonPropsType = InferProps<typeof BookButtonProps> & ButtonProps;

export const BookButton: FunctionComponent<BookButtonPropsType> = ({
  btnClassName,
  scheduleButton,
  bookingType,
  isLoading,
  onClick,

  ...other
}) => {
  const intl = useTranslation({ es });
  if (!isLoading && (!bookingType || bookingType === BookingType.NONE)) return null;

  const buttonText = getButtonText(bookingType, scheduleButton || false, intl);

  return (
    <Button
      className={btnClassName || undefined}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      isLoading={isLoading}
      {...other}
    >
      <span>
        {scheduleButton && bookingType === BookingType.CONCIERGE && (
          <i className="icon-datepicker-s i-va-fix-2" />
        )}
        {bookingType === BookingType.INSTANT && <i className="icon-lightning i-va-fix-3" />}
        &nbsp;
        {buttonText}
      </span>
    </Button>
  );
};

BookButton.propTypes = BookButtonProps;
BookButton.defaultProps = {
  btnClassName: undefined,
  scheduleButton: false,
  isLoading: false,
};
