import { Analytics } from '@getvim/analytics';

const anonymousId = 'f8699086-e9f1-4008-ae76-e786511410bd';

export class AnalyticsClient {
  private analytics: Analytics;
  public readonly metadata: object | undefined;
  protected appName: string;

  constructor(appName: string, metadata?: object) {
    this.analytics = new Analytics();
    this.metadata = metadata;
    this.appName = appName;
  }

  public track(eventName: string, properties?: object) {
    this.analytics.track({
      event: eventName,
      anonymousId,
      properties: {
        appName: this.appName,
        ...properties,
      },
    });
  }
}
