import { array, intersection, number, partial, type, TypeOf } from 'io-ts';
import { ProviderValidator } from './Provider';

export const SearchResultsTypeValidator = intersection(
  [
    type({
      providers: array(ProviderValidator),
      total: number,
    }),
    partial({
      timing: partial({
        queryBuilding: number,
        execution: number,
        serviceSideFormatting: number,
        apiPreprocessing: number,
        apiSideFormatting: number,
        overall: number,
      }),
    }),
  ],
  'SearchResultsType',
);

export type SearchResultsType = TypeOf<typeof SearchResultsTypeValidator>;
