import {
  array,
  boolean,
  intersection,
  literal,
  nullType,
  number,
  partial,
  string,
  type,
  TypeOf,
  union,
} from 'io-ts';
import { GeoCoordinatesValidator } from './Geocode';

const PhoneNumberValidator = type(
  {
    number: string,
    countryDialingCode: string,
  },
  'PhoneNumberType',
);

const DayOpenHoursValidator = type(
  {
    open: string,
    close: string,
    isOpen: boolean,
  },
  'DayOpenHours',
);

const OpenHoursValidator = union([
  partial({
    Mon: DayOpenHoursValidator,
    Tue: DayOpenHoursValidator,
    Wed: DayOpenHoursValidator,
    Thu: DayOpenHoursValidator,
    Fri: DayOpenHoursValidator,
    Sat: DayOpenHoursValidator,
    Sun: DayOpenHoursValidator,
  }),
  array(nullType),
]);

const LocationValidator = intersection(
  [
    type({
      id: number,
      geo: GeoCoordinatesValidator,
      address: string,
    }),
    partial({
      fax: union([PhoneNumberValidator, nullType]),
      timezone: string,
      officeName: string,
      phoneNumber: PhoneNumberValidator,
      addressLine1: string,
      addressLine2: union([string, nullType]),
      city: string,
      state: string,
      zip: string,
      acceptNewPatients: union([boolean, nullType]),
      distance: number,
      network: intersection([
        type({
          status: union([
            literal('IN_NETWORK'),
            literal('OUT_OF_NEWTORK'),
            literal('UNVERIFIED_NETWORK'),
          ]),
        }),
        partial({ tier: string }),
      ]),
      openHours: OpenHoursValidator,
      sampleReview: string,
      implementationSpecificFields: union([
        nullType,
        partial({
          telehealth: union([boolean, nullType]),
          webpage: union([string, nullType]),
        }),
      ]),
    }),
  ],
  'LocationType',
);

const FreeTextProviderTaxonomyV = type({
  taxonomyCode: string,
  taxonomyDisplayName: string,
  subSpecialtyDescription: string,
});

export const GetByNpiProviderTaxonomyValidator = intersection(
  [FreeTextProviderTaxonomyV, partial({ taxonomyDescription: string })],
  'GetByNpiProviderTaxonomy',
);

export const ProviderValidator = intersection(
  [
    type({
      npi: string,
      firstName: string,
      lastName: string,
      gender: union([literal('MALE'), literal('FEMALE'), literal('ANY')]),
      languages: array(
        intersection([
          type({
            id: string,
            name: string,
            nativeName: string,
          }),
          partial({
            iso3: string,
          }),
        ]),
      ),
      taxonomies: array(GetByNpiProviderTaxonomyValidator),
      locations: array(LocationValidator),
    }),
    partial({
      middleName: union([string, nullType]),
      suffix: string,
      title: union([string, nullType]),
      profilePicture: union([string, nullType]),
      acceptNewPatients: union([boolean, nullType]),
      rating: union([number, nullType]),
      ranking: number,
      boardCertifications: union([string, nullType]),
      medicalDegree: union([string, nullType]),
      hvns: array(type({ domain: string })),
      ratingCount: number,
      profileUrl: union([string, nullType]),
      clinicalMatch: nullType,
    }),
  ],
  'GetByNpiResponse',
);

export type ProviderType = TypeOf<typeof ProviderValidator>;
