import React from 'react';
import { deprecated } from '@getvim/atomic-ui';
import { useIntl } from '@getvim/translate';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import reactNativeEvent from '../../utils/reactNativeEvent';
import './SomethingWentWrongError.less';

function SomethingWentWrongError() {
  const intl = useIntl();
  const sdkEvents = useSdkEvents();

  return (
    <deprecated.ErrorPage
      className="something-went-wrong-error"
      title={intl.formatMessage({ id: 'general.somethingWentWrong' })}
      description={intl.formatMessage({ id: 'pages.somethingWentWrong.errorDescription' })}
      buttonText={intl.formatMessage({ id: 'general.closeThisWindow' })}
      onClose={() => {
        reactNativeEvent({ event: 'closed' });
        sdkEvents.closed();
      }}
      onSubmit={() => {
        reactNativeEvent({ event: 'closed' });
        sdkEvents.closed();
      }}
    />
  );
}

export default SomethingWentWrongError;
