import loadDynamicScript from '@getvim/utils-load-dynamic-script';
import { logger } from '@getvim/scheduling-logger';

declare global {
  interface Window {
    google: any;
  }
}

function snippetChecks(apiKey: string, systemName: string) {
  if (!apiKey) {
    return Promise.reject(new Error(`Can not init ${systemName} without an api key!`));
  }
  if (typeof document === 'undefined') {
    return Promise.reject(
      new Error(`document was not found, can only init ${systemName} on the client side!`),
    );
  }

  return Promise.resolve();
}

const loadGooglePlacesAutoCompleteApi = (apiKey: string, language: 'en' | 'es') => {
  if (!window?.google?.maps) {
    return snippetChecks(apiKey, 'Google places auto complete api')
      .then(() =>
        loadDynamicScript(
          'googleMaps',
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places${
            language !== 'en' ? `&language=${language}` : ''
          }`,
        ),
      )
      .then(() => {
        if (typeof window?.google?.maps === 'undefined') {
          throw new Error('Google api for places auto complete failed to initialize');
        }
        return true;
      })
      .catch((error) => {
        logger.error(`Failed to init google API: ${error}`, {
          clientName: 'provider-discovery',
          scope: 'google-api',
          metadata: {
            error,
          },
        });

        throw new Error('Failed to initialize google API script');
      });
  }
  return Promise.resolve(true);
};

export default loadGooglePlacesAutoCompleteApi;
