import React, { FunctionComponent } from 'react';

const SvgNoResultsX: FunctionComponent = () => (
  /* eslint-disable */
  <svg
    aria-hidden="true"
    focusable="false"
    width="260px"
    height="201px"
    viewBox="0 0 260 201"
    version="1.1"
    className="svg-icon svg-no-results-x"
  >
    <defs>
      <linearGradient x1="0%" y1="98.81661%" x2="100%" y2="1.18339002%" id="linearGradient-1">
        <stop stop-color={'var(--main-color)'} offset="0%"></stop>
        <stop stop-color={'var(--main-color)'} offset="100%"></stop>
      </linearGradient>
      <path
        d="M62.292,-8.52651283e-14 C27.944,-8.52651283e-14 1.42108547e-13,27.944 1.42108547e-13,62.292 C1.42108547e-13,96.641 27.944,124.584 62.292,124.584 C96.64,124.584 124.584,96.641 124.584,62.292 C124.584,27.944 96.64,-8.52651283e-14 62.292,-8.52651283e-14"
        id="path-2"
      ></path>
      <linearGradient x1="0%" y1="99.373047%" x2="100%" y2="0.626953043%" id="linearGradient-4">
        <stop stop-color={'var(--main-color)'} offset="0%"></stop>
        <stop stop-color={'var(--main-color)'} offset="100%"></stop>
      </linearGradient>
      <path
        d="M73.858,137.15 C38.903,137.15 10.566,108.813 10.566,73.859 C10.566,38.903 38.903,10.567 73.858,10.567 C108.813,10.567 137.15,38.903 137.15,73.859 C137.15,108.813 108.813,137.15 73.858,137.15 M132.033,119.207 C132.126,119.113 132.23,119.039 132.333,118.962 C141.971,106.484 147.717,90.846 147.717,73.859 C147.717,33.068 114.649,0 73.858,0 C33.067,0 0,33.068 0,73.859 C0,114.649 33.067,147.717 73.858,147.717 C90.774,147.717 106.345,142.009 118.798,132.442 L132.033,119.207"
        id="path-5"
      ></path>
      <path
        d="M130.207,130.755 L127.342,127.891 C126.239,126.788 126.239,125 127.342,123.897 L118.798,132.442 L118.658,132.581 C117.555,133.683 117.555,135.472 118.658,136.574 L122.135,140.051 C123.282,138.129 126.635,134.366 130.207,130.755"
        id="path-6"
      ></path>
      <path
        d="M127.342,123.897 C126.239,125 126.239,126.788 127.342,127.891 L130.207,130.755 C133.662,127.261 137.308,123.923 139.354,122.536 L136.025,119.207 C135.016,118.197 133.439,118.123 132.333,118.962 C132.23,119.039 132.126,119.113 132.033,119.207 L127.342,123.897"
        id="path-7"
      ></path>
      <path
        d="M130.206,130.754 L127.342,127.891 C126.79,127.339 126.515,126.617 126.515,125.894 C126.515,125.171 126.79,124.449 127.342,123.897 L132.033,119.207 C132.126,119.113 132.23,119.039 132.333,118.962 C132.838,118.579 133.442,118.386 134.045,118.386 C134.761,118.386 135.477,118.658 136.025,119.207 L139.351,122.533 C137.303,123.924 133.655,127.266 130.206,130.754"
        id="path-8"
      ></path>
      <path
        d="M192.941,174.496 L140.929,121.919 C140.742,121.729 140.173,121.98 139.354,122.536 C137.308,123.923 133.662,127.261 130.207,130.755 C126.635,134.366 123.282,138.129 122.135,140.051 C121.761,140.676 121.617,141.11 121.781,141.275 L173.793,193.853 C179.08,199.199 187.653,199.199 192.941,193.853 C198.228,188.508 198.228,179.842 192.941,174.496"
        id="path-9"
      ></path>
      <path
        d="M129.19,131.793 L129.19,131.793 C129.526,131.447 129.865,131.1 130.207,130.755 C133.662,127.261 137.308,123.923 139.354,122.536 L139.351,122.533 C139.99,122.099 140.473,121.855 140.746,121.855 C140.825,121.855 140.887,121.876 140.929,121.919 C140.888,121.877 140.827,121.856 140.749,121.856 C140.476,121.856 139.99,122.104 139.354,122.536 C137.308,123.923 133.662,127.261 130.207,130.755 C129.865,131.1 129.526,131.447 129.19,131.793"
        id="path-10"
      ></path>
      <path
        d="M129.19,131.793 C129.198,131.784 129.206,131.776 129.214,131.767 C129.542,131.429 129.873,131.091 130.206,130.754 L130.207,130.755 C129.865,131.1 129.526,131.447 129.19,131.793"
        id="path-11"
      ></path>
      <path
        d="M130.207,130.755 L130.207,130.755 L130.206,130.754 C133.655,127.266 137.303,123.924 139.351,122.533 L139.354,122.536 C137.308,123.923 133.662,127.261 130.207,130.755"
        id="path-12"
      ></path>
      <path
        d="M192.019,194.175 C182.673,194.169 125.279,136.272 129.19,131.793 C129.526,131.447 129.865,131.1 130.207,130.755 C133.662,127.261 137.308,123.923 139.354,122.536 C139.99,122.104 140.476,121.856 140.749,121.856 C140.827,121.856 140.888,121.877 140.929,121.919 L192.941,174.496 C195.585,177.169 196.906,180.672 196.906,184.175 C196.906,187.678 195.585,191.181 192.941,193.853 C192.726,194.071 192.417,194.175 192.019,194.175"
        id="path-13"
      ></path>
    </defs>
    <g id="web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard" transform="translate(-265.000000, -189.000000)">
        <g id="Group" transform="translate(266.000000, 190.000000)">
          <g id="Group-7" transform="translate(0.000000, 13.000000)" stroke-linecap="round">
            <g id="Group-3" transform="translate(27.000000, 0.000640)">
              <g
                id="Group-2"
                transform="translate(0.500000, 0.000000)"
                opacity="0.6"
                stroke="url(#linearGradient-1)"
                stroke-linejoin="round"
                stroke-width="5"
              >
                <line
                  x1="2.14705882"
                  y1="164.371909"
                  x2="166.852941"
                  y2="1.62681091"
                  id="Line"
                ></line>
                <line
                  x1="2.14705882"
                  y1="164.371909"
                  x2="166.852941"
                  y2="1.62681091"
                  id="Line-Copy"
                  transform="translate(84.500000, 82.999360) scale(-1, 1) translate(-84.500000, -82.999360) "
                ></line>
              </g>
              <line
                x1="119.518382"
                y1="173.284272"
                x2="181.621324"
                y2="173.284272"
                id="Line-2-Copy-4"
                stroke="#0052A4"
              ></line>
              <line
                x1="169"
                y1="185.99936"
                x2="231.102941"
                y2="185.99936"
                id="Line-2-Copy-3"
                stroke="#0052A4"
              ></line>
            </g>
            <line
              x1="71"
              y1="157.50064"
              x2="133"
              y2="157.50064"
              id="Line-2-Copy"
              stroke="#0052A4"
            ></line>
            <line
              x1="0.380530973"
              y1="180.50064"
              x2="85.619469"
              y2="180.50064"
              id="Line-2"
              stroke="#0052A4"
            ></line>
            <line
              x1="15"
              y1="167.50064"
              x2="47"
              y2="167.50064"
              id="Line-2-Copy-2"
              stroke="#0052A4"
            ></line>
          </g>
          <g id="Group-14" transform="translate(31.000000, 0.000000)">
            <g id="Group-2" transform="translate(11.566000, 11.566000)">
              <mask id="mask-3" fill="white">
                <use xlinkHref="#path-2"></use>
              </mask>
              <g id="Mask">
                <use fill="#FFFFFF" xlinkHref="#path-2"></use>
                <use fill-opacity="0.1" fill={'var(--main-color)'} xlinkHref="#path-2"></use>
              </g>
              <g
                mask="url(#mask-3)"
                fill="url(#linearGradient-4)"
                fill-opacity="0.8"
                fill-rule="nonzero"
                id="Combined-Shape"
              >
                <g transform="translate(-14.566000, -14.565360)">
                  <path d="M152.742167,4.31540438 C157.620645,9.23084041 157.669853,17.1216005 152.912019,22.0972392 L152.674152,22.3395665 L96.592823,77.998379 L152.674152,133.659153 L152.912019,133.901481 C157.669853,138.877119 157.620645,146.767879 152.742167,151.683315 C147.783714,156.679332 139.714022,156.709784 134.718005,151.751331 L134.718005,151.751331 L78.499823,95.955379 L22.2819948,151.751331 C17.2859778,156.709784 9.21628557,156.679332 4.25783266,151.683315 C-0.620645208,146.767879 -0.669853056,138.877119 4.08798102,133.901481 L4.32584836,133.659153 L60.406823,77.998379 L4.32584836,22.3395665 L4.08798102,22.0972392 C-0.669853056,17.1216005 -0.620645208,9.23084041 4.25783266,4.31540438 C9.21628557,-0.680612575 17.2859778,-0.711064244 22.2819948,4.24738867 L22.2819948,4.24738867 L78.499823,60.042379 L134.718005,4.24738867 C139.714022,-0.711064244 147.783714,-0.680612575 152.742167,4.31540438 Z"></path>
                </g>
              </g>
            </g>
            <g id="Group-13">
              <path
                d="M35.973,47.78 C34.6333877,49.894388 33.4506846,52.1127159 32.4358811,54.4259913 L32.435,54.428 C29.751,60.548 28.261,67.307 28.261,74.407 C28.261,76.616 26.469,78.407 24.26,78.407 C22.052,78.407 20.26,76.616 20.26,74.407 C20.26,61.0484525 24.806692,48.7318344 32.4342249,38.9239967 L32.435,38.923 C43.039,25.289 59.595,16.502 78.165,16.502 C80.374,16.502 82.165,18.293 82.165,20.502 C82.165,22.711 80.374,24.502 78.165,24.502 C67.129,24.502 56.918,28.103 48.646,34.191 L40.55,32.699 L40.633,32.715 L48.645,34.192 C43.625,37.887 39.317,42.5 35.973,47.779 L35.973,47.78 Z"
                id="Combined-Shape"
                fill="#FFFFFF"
              ></path>
              <g id="Fill-201">
                <use fill="#FFFFFF" xlinkHref="#path-5"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-5"
                ></use>
              </g>
              <g id="Fill-203">
                <use fill="#FFFFFF" xlinkHref="#path-6"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-6"
                ></use>
              </g>
              <g id="Fill-204">
                <use fill="#FFFFFF" xlinkHref="#path-7"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-7"
                ></use>
              </g>
              <g id="Fill-205">
                <use fill="#FFFFFF" xlinkHref="#path-8"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-8"
                ></use>
              </g>
              <g id="Fill-206">
                <use fill="#FFFFFF" xlinkHref="#path-9"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-9"
                ></use>
              </g>
              <g id="Fill-207">
                <use fill="#FFFFFF" xlinkHref="#path-10"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-10"
                ></use>
              </g>
              <g id="Fill-208">
                <use fill="#FFFFFF" xlinkHref="#path-11"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-11"
                ></use>
              </g>
              <g id="Fill-209">
                <use fill="#FFFFFF" xlinkHref="#path-12"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-12"
                ></use>
              </g>
              <g id="Fill-210">
                <use fill="#FFFFFF" xlinkHref="#path-13"></use>
                <use
                  stroke={'var(--main-color)'}
                  stroke-width="1"
                  fill-opacity="0.15"
                  fill={'var(--main-color)'}
                  xlinkHref="#path-13"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  /* eslint-enable */
);

export default SvgNoResultsX;
