import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Loader } from '@getvim/atomic-ui';
import { getJwt } from '../api';
import { getAccessToken } from '../api/tokensStorage';

export default function AuthProvider({ children }: PropsWithChildren<any>) {
  const [isAuthReady, setIsAuthReady] = useState(false);
  const accessToken = getAccessToken();

  useEffect(() => {
    if (!accessToken) {
      (async () => {
        await getJwt();
        setIsAuthReady(true);
      })();
    }
  }, [accessToken]);

  return isAuthReady ? children : <Loader />;
}
