import { useMemo } from 'react';
import { getAccessToken } from '../api/tokensStorage';

interface ApiKeyPreferences {
  id: number;
  clientId: string;
  domain: string;
  tenant: string;
  apiKey: string;
  theme: string;
}

export default function useApiKeyPreferences(): Partial<ApiKeyPreferences> {
  const accessToken = getAccessToken();

  return useMemo(() => {
    try {
      const jwtPayloadString = accessToken?.split?.('.')?.[1];

      return JSON.parse(atob(jwtPayloadString as string));
    } catch (error) {
      return {};
    }
  }, [accessToken]);
}
