import { defineMessages } from 'react-intl';

// eslint-disable-next-line max-line-length-next
export type LangEnum =
  | 'other'
  | 'ANY'
  | 'aa'
  | 'ab'
  | 'af'
  | 'ak'
  | 'am'
  | 'ar'
  | 'an'
  | 'hy'
  | 'is'
  | 'my'
  | 'bo'
  | 'cy'
  | 'cs'
  | 'de'
  | 'nl'
  | 'el'
  | 'eu'
  | 'fr'
  | 'ka'
  | 'mk'
  | 'mi'
  | 'ms'
  | 'fa'
  | 'ro'
  | 'sk'
  | 'sq'
  | 'zh'
  | 'as'
  | 'av'
  | 'ay'
  | 'az'
  | 'ba'
  | 'bm'
  | 'be'
  | 'bn'
  | 'bi'
  | 'bs'
  | 'br'
  | 'bg'
  | 'ca'
  | 'ch'
  | 'ce'
  | 'cv'
  | 'kw'
  | 'co'
  | 'cr'
  | 'da'
  | 'dv'
  | 'dz'
  | 'en'
  | 'et'
  | 'ee'
  | 'fo'
  | 'fj'
  | 'fi'
  | 'fy'
  | 'ff'
  | 'gd'
  | 'ga'
  | 'gl'
  | 'gv'
  | 'gn'
  | 'gu'
  | 'ht'
  | 'ha'
  | 'he'
  | 'hz'
  | 'hi'
  | 'ho'
  | 'hr'
  | 'hu'
  | 'ig'
  | 'ii'
  | 'iu'
  | 'id'
  | 'ik'
  | 'it'
  | 'jv'
  | 'ja'
  | 'kl'
  | 'kn'
  | 'ks'
  | 'kr'
  | 'kk'
  | 'km'
  | 'ki'
  | 'rw'
  | 'ky'
  | 'kv'
  | 'kg'
  | 'ko'
  | 'kj'
  | 'ku'
  | 'lo'
  | 'lv'
  | 'li'
  | 'ln'
  | 'lt'
  | 'lb'
  | 'lu'
  | 'lg'
  | 'mh'
  | 'ml'
  | 'mr'
  | 'mg'
  | 'mt'
  | 'mn'
  | 'na'
  | 'nv'
  | 'nr'
  | 'nd'
  | 'ng'
  | 'ne'
  | 'nn'
  | 'nb'
  | 'no'
  | 'ny'
  | 'oc'
  | 'oj'
  | 'or'
  | 'om'
  | 'os'
  | 'pa'
  | 'pl'
  | 'pt'
  | 'ps'
  | 'qu'
  | 'rm'
  | 'rn'
  | 'ru'
  | 'sg'
  | 'si'
  | 'sl'
  | 'se'
  | 'sm'
  | 'sn'
  | 'sd'
  | 'so'
  | 'st'
  | 'es'
  | 'sc'
  | 'sr'
  | 'ss'
  | 'su'
  | 'sw'
  | 'sv'
  | 'ty'
  | 'ta'
  | 'tt'
  | 'te'
  | 'tg'
  | 'tl'
  | 'th'
  | 'ti'
  | 'to'
  | 'tn'
  | 'ts'
  | 'tk'
  | 'tr'
  | 'tw'
  | 'ug'
  | 'uk'
  | 'ur'
  | 'uz'
  | 've'
  | 'vi'
  | 'wa'
  | 'wo'
  | 'xh'
  | 'yi'
  | 'yo'
  | 'za'
  | 'zu';

export const messages = defineMessages({
  other: { id: 'languageSelector.other', defaultMessage: 'Other' },
  any: { id: 'languageSelector.any', defaultMessage: 'Any' },
  aa: { id: 'languageSelector.language.aa', defaultMessage: 'Afar' },
  ab: { id: 'languageSelector.language.ab', defaultMessage: 'Abkhazian' },
  af: { id: 'languageSelector.language.af', defaultMessage: 'Afrikaans' },
  ak: { id: 'languageSelector.language.ak', defaultMessage: 'Akan' },
  am: { id: 'languageSelector.language.am', defaultMessage: 'Amharic' },
  ar: { id: 'languageSelector.language.ar', defaultMessage: 'Arabic' },
  an: { id: 'languageSelector.language.an', defaultMessage: 'Aragonese' },
  hy: { id: 'languageSelector.language.hy', defaultMessage: 'Armenian' },
  is: { id: 'languageSelector.language.is', defaultMessage: 'Icelandic' },
  my: { id: 'languageSelector.language.my', defaultMessage: 'Burmese' },
  bo: { id: 'languageSelector.language.bo', defaultMessage: 'Tibetan' },
  cy: { id: 'languageSelector.language.cy', defaultMessage: 'Welsh' },
  cs: { id: 'languageSelector.language.cs', defaultMessage: 'Czech' },
  de: { id: 'languageSelector.language.de', defaultMessage: 'German' },
  nl: { id: 'languageSelector.language.nl', defaultMessage: 'Dutch / Flemish' },
  el: { id: 'languageSelector.language.el', defaultMessage: 'Modern Greek (1453–)' },
  eu: { id: 'languageSelector.language.eu', defaultMessage: 'Basque' },
  fr: { id: 'languageSelector.language.fr', defaultMessage: 'French' },
  ka: { id: 'languageSelector.language.ka', defaultMessage: 'Georgian' },
  mk: { id: 'languageSelector.language.mk', defaultMessage: 'Macedonian' },
  mi: { id: 'languageSelector.language.mi', defaultMessage: 'Maori' },
  ms: { id: 'languageSelector.language.ms', defaultMessage: 'Malay' },
  fa: { id: 'languageSelector.language.fa', defaultMessage: 'Persian' },
  ro: { id: 'languageSelector.language.ro', defaultMessage: 'Romanian / Moldavian / Moldovan' },
  sk: { id: 'languageSelector.language.sk', defaultMessage: 'Slovak' },
  sq: { id: 'languageSelector.language.sq', defaultMessage: 'Albanian' },
  zh: { id: 'languageSelector.language.zh', defaultMessage: 'Chinese' },
  as: { id: 'languageSelector.language.as', defaultMessage: 'Assamese' },
  av: { id: 'languageSelector.language.av', defaultMessage: 'Avaric' },
  ay: { id: 'languageSelector.language.ay', defaultMessage: 'Aymara' },
  az: { id: 'languageSelector.language.az', defaultMessage: 'Azerbaijani' },
  ba: { id: 'languageSelector.language.ba', defaultMessage: 'Bashkir' },
  bm: { id: 'languageSelector.language.bm', defaultMessage: 'Bambara' },
  be: { id: 'languageSelector.language.be', defaultMessage: 'Belarusian' },
  bn: { id: 'languageSelector.language.bn', defaultMessage: 'Bengali' },
  bi: { id: 'languageSelector.language.bi', defaultMessage: 'Bislama' },
  bs: { id: 'languageSelector.language.bs', defaultMessage: 'Bosnian' },
  br: { id: 'languageSelector.language.br', defaultMessage: 'Breton' },
  bg: { id: 'languageSelector.language.bg', defaultMessage: 'Bulgarian' },
  ca: { id: 'languageSelector.language.ca', defaultMessage: 'Catalan / Valencian' },
  ch: { id: 'languageSelector.language.ch', defaultMessage: 'Chamorro' },
  ce: { id: 'languageSelector.language.ce', defaultMessage: 'Chechen' },
  cv: { id: 'languageSelector.language.cv', defaultMessage: 'Chuvash' },
  kw: { id: 'languageSelector.language.kw', defaultMessage: 'Cornish' },
  co: { id: 'languageSelector.language.co', defaultMessage: 'Corsican' },
  cr: { id: 'languageSelector.language.cr', defaultMessage: 'Cree' },
  da: { id: 'languageSelector.language.da', defaultMessage: 'Danish' },
  dv: { id: 'languageSelector.language.dv', defaultMessage: 'Dhivehi / Dhivehi / Maldivian' },
  dz: { id: 'languageSelector.language.dz', defaultMessage: 'Dzongkha' },
  en: { id: 'languageSelector.language.en', defaultMessage: 'English' },
  et: { id: 'languageSelector.language.et', defaultMessage: 'Estonian' },
  ee: { id: 'languageSelector.language.ee', defaultMessage: 'Ewe' },
  fo: { id: 'languageSelector.language.fo', defaultMessage: 'Faroese' },
  fj: { id: 'languageSelector.language.fj', defaultMessage: 'Fijian' },
  fi: { id: 'languageSelector.language.fi', defaultMessage: 'Finnish' },
  fy: { id: 'languageSelector.language.fy', defaultMessage: 'Western Frisian' },
  ff: { id: 'languageSelector.language.ff', defaultMessage: 'Fulah' },
  gd: { id: 'languageSelector.language.gd', defaultMessage: 'Gaelic / Scottish Gaelic' },
  ga: { id: 'languageSelector.language.ga', defaultMessage: 'Irish' },
  gl: { id: 'languageSelector.language.gl', defaultMessage: 'Galician' },
  gv: { id: 'languageSelector.language.gv', defaultMessage: 'Manx' },
  gn: { id: 'languageSelector.language.gn', defaultMessage: 'Guarani' },
  gu: { id: 'languageSelector.language.gu', defaultMessage: 'Gujarati' },
  ht: { id: 'languageSelector.language.ht', defaultMessage: 'Haitian / Haitian Creole' },
  ha: { id: 'languageSelector.language.ha', defaultMessage: 'Hausa' },
  he: { id: 'languageSelector.language.he', defaultMessage: 'Hebrew' },
  hz: { id: 'languageSelector.language.hz', defaultMessage: 'Herero' },
  hi: { id: 'languageSelector.language.hi', defaultMessage: 'Hindi' },
  ho: { id: 'languageSelector.language.ho', defaultMessage: 'Hiri Motu' },
  hr: { id: 'languageSelector.language.hr', defaultMessage: 'Croatian' },
  hu: { id: 'languageSelector.language.hu', defaultMessage: 'Hungarian' },
  ig: { id: 'languageSelector.language.ig', defaultMessage: 'Igbo' },
  ii: { id: 'languageSelector.language.ii', defaultMessage: 'Sichuan Yi / Nuosu' },
  iu: { id: 'languageSelector.language.iu', defaultMessage: 'Inuktitut' },
  id: { id: 'languageSelector.language.id', defaultMessage: 'Indonesian' },
  ik: { id: 'languageSelector.language.ik', defaultMessage: 'Inupiaq' },
  it: { id: 'languageSelector.language.it', defaultMessage: 'Italian' },
  jv: { id: 'languageSelector.language.jv', defaultMessage: 'Javanese' },
  ja: { id: 'languageSelector.language.ja', defaultMessage: 'Japanese' },
  kl: { id: 'languageSelector.language.kl', defaultMessage: 'Kalaallisut / Greenlandic' },
  kn: { id: 'languageSelector.language.kn', defaultMessage: 'Kannada' },
  ks: { id: 'languageSelector.language.ks', defaultMessage: 'Kashmiri' },
  kr: { id: 'languageSelector.language.kr', defaultMessage: 'Kanuri' },
  kk: { id: 'languageSelector.language.kk', defaultMessage: 'Kazakh' },
  km: { id: 'languageSelector.language.km', defaultMessage: 'Central Khmer' },
  ki: { id: 'languageSelector.language.ki', defaultMessage: 'Kikuyu / Gikuyu' },
  rw: { id: 'languageSelector.language.rw', defaultMessage: 'Kinyarwanda' },
  ky: { id: 'languageSelector.language.ky', defaultMessage: 'Kirghiz / Kyrgyz' },
  kv: { id: 'languageSelector.language.kv', defaultMessage: 'Komi' },
  kg: { id: 'languageSelector.language.kg', defaultMessage: 'Kongo' },
  ko: { id: 'languageSelector.language.ko', defaultMessage: 'Korean' },
  kj: { id: 'languageSelector.language.kj', defaultMessage: 'Kuanyama / Kwanyama' },
  ku: { id: 'languageSelector.language.ku', defaultMessage: 'Kurdish' },
  lo: { id: 'languageSelector.language.lo', defaultMessage: 'Lao' },
  lv: { id: 'languageSelector.language.lv', defaultMessage: 'Latvian' },
  li: { id: 'languageSelector.language.li', defaultMessage: 'Limburgan / Limburger / Limburgish' },
  ln: { id: 'languageSelector.language.ln', defaultMessage: 'Lingala' },
  lt: { id: 'languageSelector.language.lt', defaultMessage: 'Lithuanian' },
  lb: { id: 'languageSelector.language.lb', defaultMessage: 'Luxembourgish / Letzeburgesch' },
  lu: { id: 'languageSelector.language.lu', defaultMessage: 'Luba-Katanga' },
  lg: { id: 'languageSelector.language.lg', defaultMessage: 'Ganda' },
  mh: { id: 'languageSelector.language.mh', defaultMessage: 'Marshallese' },
  ml: { id: 'languageSelector.language.ml', defaultMessage: 'Malayalam' },
  mr: { id: 'languageSelector.language.mr', defaultMessage: 'Marathi' },
  mg: { id: 'languageSelector.language.mg', defaultMessage: 'Malagasy' },
  mt: { id: 'languageSelector.language.mt', defaultMessage: 'Maltese' },
  mn: { id: 'languageSelector.language.mn', defaultMessage: 'Mongolian' },
  na: { id: 'languageSelector.language.na', defaultMessage: 'Nauru' },
  nv: { id: 'languageSelector.language.nv', defaultMessage: 'Navajo / Navaho' },
  nr: { id: 'languageSelector.language.nr', defaultMessage: 'South Ndebele' },
  nd: { id: 'languageSelector.language.nd', defaultMessage: 'North Ndebele' },
  ng: { id: 'languageSelector.language.ng', defaultMessage: 'Ndonga' },
  ne: { id: 'languageSelector.language.ne', defaultMessage: 'Nepali' },
  nn: { id: 'languageSelector.language.nn', defaultMessage: 'Norwegian Nynorsk' },
  nb: { id: 'languageSelector.language.nb', defaultMessage: 'Norwegian Bokmål' },
  no: { id: 'languageSelector.language.no', defaultMessage: 'Norwegian' },
  ny: { id: 'languageSelector.language.ny', defaultMessage: 'Chichewa / Chewa / Nyanja' },
  oc: { id: 'languageSelector.language.oc', defaultMessage: 'Occitan (post 1500)' },
  oj: { id: 'languageSelector.language.oj', defaultMessage: 'Ojibwa' },
  or: { id: 'languageSelector.language.or', defaultMessage: 'Oriya' },
  om: { id: 'languageSelector.language.om', defaultMessage: 'Oromo' },
  os: { id: 'languageSelector.language.os', defaultMessage: 'Ossetian / Ossetic' },
  pa: { id: 'languageSelector.language.pa', defaultMessage: 'Panjabi / Punjabi' },
  pl: { id: 'languageSelector.language.pl', defaultMessage: 'Polish' },
  pt: { id: 'languageSelector.language.pt', defaultMessage: 'Portuguese' },
  ps: { id: 'languageSelector.language.ps', defaultMessage: 'Pushto / Pashto' },
  qu: { id: 'languageSelector.language.qu', defaultMessage: 'Quechua' },
  rm: { id: 'languageSelector.language.rm', defaultMessage: 'Romansh' },
  rn: { id: 'languageSelector.language.rn', defaultMessage: 'Rundi' },
  ru: { id: 'languageSelector.language.ru', defaultMessage: 'Russian' },
  sg: { id: 'languageSelector.language.sg', defaultMessage: 'Sango' },
  si: { id: 'languageSelector.language.si', defaultMessage: 'Sinhala / Sinhalese' },
  sl: { id: 'languageSelector.language.sl', defaultMessage: 'Slovenian' },
  se: { id: 'languageSelector.language.se', defaultMessage: 'Northern Sami' },
  sm: { id: 'languageSelector.language.sm', defaultMessage: 'Samoan' },
  sn: { id: 'languageSelector.language.sn', defaultMessage: 'Shona' },
  sd: { id: 'languageSelector.language.sd', defaultMessage: 'Sindhi' },
  so: { id: 'languageSelector.language.so', defaultMessage: 'Somali' },
  st: { id: 'languageSelector.language.st', defaultMessage: 'Southern Sotho' },
  es: { id: 'languageSelector.language.es', defaultMessage: 'Spanish' },
  sc: { id: 'languageSelector.language.sc', defaultMessage: 'Sardinian' },
  sr: { id: 'languageSelector.language.sr', defaultMessage: 'Serbian' },
  ss: { id: 'languageSelector.language.ss', defaultMessage: 'Swati' },
  su: { id: 'languageSelector.language.su', defaultMessage: 'Sundanese' },
  sw: { id: 'languageSelector.language.sw', defaultMessage: 'Swahili' },
  sv: { id: 'languageSelector.language.sv', defaultMessage: 'Swedish' },
  ty: { id: 'languageSelector.language.ty', defaultMessage: 'Tahitian' },
  ta: { id: 'languageSelector.language.ta', defaultMessage: 'Tamil' },
  tt: { id: 'languageSelector.language.tt', defaultMessage: 'Tatar' },
  te: { id: 'languageSelector.language.te', defaultMessage: 'Telugu' },
  tg: { id: 'languageSelector.language.tg', defaultMessage: 'Tajik' },
  tl: { id: 'languageSelector.language.tl', defaultMessage: 'Tagalog' },
  th: { id: 'languageSelector.language.th', defaultMessage: 'Thai' },
  ti: { id: 'languageSelector.language.ti', defaultMessage: 'Tigrinya' },
  to: { id: 'languageSelector.language.to', defaultMessage: 'Tonga (Tonga Islands)' },
  tn: { id: 'languageSelector.language.tn', defaultMessage: 'Tswana' },
  ts: { id: 'languageSelector.language.ts', defaultMessage: 'Tsonga' },
  tk: { id: 'languageSelector.language.tk', defaultMessage: 'Turkmen' },
  tr: { id: 'languageSelector.language.tr', defaultMessage: 'Turkish' },
  tw: { id: 'languageSelector.language.tw', defaultMessage: 'Twi' },
  ug: { id: 'languageSelector.language.ug', defaultMessage: 'Uighur / Uyghur' },
  uk: { id: 'languageSelector.language.uk', defaultMessage: 'Ukrainian' },
  ur: { id: 'languageSelector.language.ur', defaultMessage: 'Urdu' },
  uz: { id: 'languageSelector.language.uz', defaultMessage: 'Uzbek' },
  ve: { id: 'languageSelector.language.ve', defaultMessage: 'Venda' },
  vi: { id: 'languageSelector.language.vi', defaultMessage: 'Vietnamese' },
  wa: { id: 'languageSelector.language.wa', defaultMessage: 'Walloon' },
  wo: { id: 'languageSelector.language.wo', defaultMessage: 'Wolof' },
  xh: { id: 'languageSelector.language.xh', defaultMessage: 'Xhosa' },
  yi: { id: 'languageSelector.language.yi', defaultMessage: 'Yiddish' },
  yo: { id: 'languageSelector.language.yo', defaultMessage: 'Yoruba' },
  za: { id: 'languageSelector.language.za', defaultMessage: 'Zhuang / Chuang' },
  zu: { id: 'languageSelector.language.zu', defaultMessage: 'Zulu' },
});

export const languages: {
  id: LangEnum;
  iso3: string[];
  name: {
    id: string;
    defaultMessage: string;
  };
  nativeName: string;
}[] = [
  { id: 'aa', iso3: ['aar'], name: messages.aa, nativeName: 'Qafaraf' },
  { id: 'ab', iso3: ['abk'], name: messages.ab, nativeName: 'Аҧсуа бызшәа' },
  { id: 'af', iso3: ['afr'], name: messages.af, nativeName: 'Afrikaans' },
  { id: 'ak', iso3: ['aka'], name: messages.ak, nativeName: 'Akan' },
  { id: 'am', iso3: ['amh'], name: messages.am, nativeName: 'አማርኛ' },
  { id: 'ar', iso3: ['ara'], name: messages.ar, nativeName: 'العَرَبِيَّة' },
  { id: 'an', iso3: ['arg'], name: messages.an, nativeName: 'aragonés' },
  { id: 'hy', iso3: ['arm', 'hye'], name: messages.hy, nativeName: 'Հայերէն' },
  { id: 'is', iso3: ['isl', 'ice'], name: messages.is, nativeName: 'íslenska' },
  { id: 'my', iso3: ['bur', 'mya'], name: messages.my, nativeName: 'မြန်မာစာ' },
  { id: 'bo', iso3: ['bod', 'tib'], name: messages.bo, nativeName: 'བོད་སྐད་' },
  { id: 'cy', iso3: ['cym', 'wel'], name: messages.cy, nativeName: 'Cymraeg' },
  { id: 'cs', iso3: ['cze', 'ces'], name: messages.cs, nativeName: 'čeština' },
  { id: 'de', iso3: ['deu', 'ger'], name: messages.de, nativeName: 'Deutsch' },
  { id: 'nl', iso3: ['dut', 'nld'], name: messages.nl, nativeName: 'Nederlands' },
  { id: 'el', iso3: ['ell', 'gre'], name: messages.el, nativeName: 'Νέα Ελληνικά' },
  { id: 'eu', iso3: ['eus', 'baq'], name: messages.eu, nativeName: 'euskara' },
  { id: 'fr', iso3: ['fra', 'fre'], name: messages.fr, nativeName: 'français' },
  { id: 'ka', iso3: ['geo', 'kat'], name: messages.ka, nativeName: 'ქართული' },
  { id: 'mk', iso3: ['mkd', 'mac'], name: messages.mk, nativeName: 'македонски јазик' },
  { id: 'mi', iso3: ['mri', 'mao'], name: messages.mi, nativeName: 'Te Reo Māori' },
  { id: 'ms', iso3: ['msa', 'may'], name: messages.ms, nativeName: 'Bahasa Melayu' },
  { id: 'fa', iso3: ['per', 'fas'], name: messages.fa, nativeName: 'فارسی' },
  { id: 'ro', iso3: ['ron', 'rum'], name: messages.ro, nativeName: 'limba română' },
  { id: 'sk', iso3: ['slo', 'slk'], name: messages.sk, nativeName: 'slovenčina' },
  { id: 'sq', iso3: ['sqi', 'alb'], name: messages.sq, nativeName: 'Shqip' },
  { id: 'zh', iso3: ['zho', 'chi'], name: messages.zh, nativeName: '中文' },
  { id: 'as', iso3: ['asm'], name: messages.as, nativeName: 'অসমীয়া' },
  { id: 'av', iso3: ['ava'], name: messages.av, nativeName: 'Магӏарул мацӏ' },
  { id: 'ay', iso3: ['aym'], name: messages.ay, nativeName: 'Aymar aru' },
  { id: 'az', iso3: ['aze'], name: messages.az, nativeName: 'Azərbaycan dili' },
  { id: 'ba', iso3: ['bak'], name: messages.ba, nativeName: 'Башҡорт теле' },
  { id: 'bm', iso3: ['bam'], name: messages.bm, nativeName: 'ߓߊߡߊߣߊߣߞߊߣ‎' },
  { id: 'be', iso3: ['bel'], name: messages.be, nativeName: 'Беларуская мова' },
  { id: 'bn', iso3: ['ben'], name: messages.bn, nativeName: 'বাংলা' },
  { id: 'bi', iso3: ['bis'], name: messages.bi, nativeName: 'Bislama' },
  { id: 'bs', iso3: ['bos'], name: messages.bs, nativeName: 'bosanski' },
  { id: 'br', iso3: ['bre'], name: messages.br, nativeName: 'Brezhoneg' },
  { id: 'bg', iso3: ['bul'], name: messages.bg, nativeName: 'български език' },
  { id: 'ca', iso3: ['cat'], name: messages.ca, nativeName: 'català' },
  { id: 'ch', iso3: ['cha'], name: messages.ch, nativeName: 'Finu Chamoru' },
  { id: 'ce', iso3: ['che'], name: messages.ce, nativeName: 'Нохчийн мотт' },
  { id: 'cv', iso3: ['chv'], name: messages.cv, nativeName: 'Чӑвашла' },
  { id: 'kw', iso3: ['cor'], name: messages.kw, nativeName: 'Kernowek' },
  { id: 'co', iso3: ['cos'], name: messages.co, nativeName: 'Corsu' },
  { id: 'cr', iso3: ['cre'], name: messages.cr, nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { id: 'da', iso3: ['dan'], name: messages.da, nativeName: 'dansk' },
  { id: 'dv', iso3: ['div'], name: messages.dv, nativeName: 'ދިވެހި' },
  { id: 'dz', iso3: ['dzo'], name: messages.dz, nativeName: 'རྫོང་ཁ་' },
  { id: 'en', iso3: ['eng'], name: messages.en, nativeName: 'English' },
  { id: 'et', iso3: ['est'], name: messages.et, nativeName: 'eesti keel' },
  { id: 'ee', iso3: ['ewe'], name: messages.ee, nativeName: 'Èʋegbe' },
  { id: 'fo', iso3: ['fao'], name: messages.fo, nativeName: 'føroyskt' },
  { id: 'fj', iso3: ['fij'], name: messages.fj, nativeName: 'Na Vosa Vakaviti' },
  { id: 'fi', iso3: ['fin'], name: messages.fi, nativeName: 'suomen kieli' },
  { id: 'fy', iso3: ['fry'], name: messages.fy, nativeName: 'Frysk' },
  { id: 'ff', iso3: ['ful'], name: messages.ff, nativeName: 'Fulfulde' },
  { id: 'gd', iso3: ['gla'], name: messages.gd, nativeName: 'Gàidhlig' },
  { id: 'ga', iso3: ['gle'], name: messages.ga, nativeName: 'Gaeilge' },
  { id: 'gl', iso3: ['glg'], name: messages.gl, nativeName: 'galego' },
  { id: 'gv', iso3: ['glv'], name: messages.gv, nativeName: 'Gaelg' },
  { id: 'gn', iso3: ['grn'], name: messages.gn, nativeName: 'Avañeẽ' },
  { id: 'gu', iso3: ['guj'], name: messages.gu, nativeName: 'ગુજરાતી' },
  { id: 'ht', iso3: ['hat'], name: messages.ht, nativeName: 'kreyòl ayisyen' },
  { id: 'ha', iso3: ['hau'], name: messages.ha, nativeName: 'Harshen Hausa' },
  { id: 'he', iso3: ['heb'], name: messages.he, nativeName: 'עברית‎' },
  { id: 'hz', iso3: ['her'], name: messages.hz, nativeName: 'Otjiherero' },
  { id: 'hi', iso3: ['hin'], name: messages.hi, nativeName: 'हिन्दी' },
  { id: 'ho', iso3: ['hmo'], name: messages.ho, nativeName: 'Hiri Motu' },
  { id: 'hr', iso3: ['hrv'], name: messages.hr, nativeName: 'hrvatski' },
  { id: 'hu', iso3: ['hun'], name: messages.hu, nativeName: 'magyar nyelv' },
  { id: 'ig', iso3: ['ibo'], name: messages.ig, nativeName: 'Asụsụ Igbo' },
  { id: 'ii', iso3: ['iii'], name: messages.ii, nativeName: 'ꆈꌠꉙ' },
  { id: 'iu', iso3: ['iku'], name: messages.iu, nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { id: 'id', iso3: ['ind'], name: messages.id, nativeName: 'bahasa Indonesia' },
  { id: 'ik', iso3: ['ipk'], name: messages.ik, nativeName: 'Iñupiaq' },
  { id: 'it', iso3: ['ita'], name: messages.it, nativeName: 'italiano' },
  { id: 'jv', iso3: ['jav'], name: messages.jv, nativeName: 'ꦧꦱꦗꦮ' },
  { id: 'ja', iso3: ['jpn'], name: messages.ja, nativeName: '日本語' },
  { id: 'kl', iso3: ['kal'], name: messages.kl, nativeName: 'kalaallisut' },
  { id: 'kn', iso3: ['kan'], name: messages.kn, nativeName: 'ಕನ್ನಡ' },
  { id: 'ks', iso3: ['kas'], name: messages.ks, nativeName: 'कॉशुर / كأشُر' },
  { id: 'kr', iso3: ['kau'], name: messages.kr, nativeName: 'Kanuri' },
  { id: 'kk', iso3: ['kaz'], name: messages.kk, nativeName: 'қазақ тілі / qazaq tili' },
  { id: 'km', iso3: ['khm'], name: messages.km, nativeName: 'ភាសាខ្មែរ' },
  { id: 'ki', iso3: ['kik'], name: messages.ki, nativeName: 'Gĩkũyũ' },
  { id: 'rw', iso3: ['kin'], name: messages.rw, nativeName: 'Ikinyarwanda' },
  { id: 'ky', iso3: ['kir'], name: messages.ky, nativeName: 'кыргызча' },
  { id: 'kv', iso3: ['kom'], name: messages.kv, nativeName: 'Коми кыв' },
  { id: 'kg', iso3: ['kon'], name: messages.kg, nativeName: 'Kikongo' },
  { id: 'ko', iso3: ['kor'], name: messages.ko, nativeName: '한국어' },
  { id: 'kj', iso3: ['kua'], name: messages.kj, nativeName: 'Kuanyama' },
  { id: 'ku', iso3: ['kur'], name: messages.ku, nativeName: 'Kurdî / کوردی‎' },
  { id: 'lo', iso3: ['lao'], name: messages.lo, nativeName: 'ພາສາລາວ' },
  { id: 'lv', iso3: ['lav'], name: messages.lv, nativeName: 'Latviešu valoda' },
  { id: 'li', iso3: ['lim'], name: messages.li, nativeName: 'Lèmburgs' },
  { id: 'ln', iso3: ['lin'], name: messages.ln, nativeName: 'Lingála' },
  { id: 'lt', iso3: ['lit'], name: messages.lt, nativeName: 'lietuvių kalba' },
  { id: 'lb', iso3: ['ltz'], name: messages.lb, nativeName: 'Lëtzebuergesch' },
  { id: 'lu', iso3: ['lub'], name: messages.lu, nativeName: 'Kiluba' },
  { id: 'lg', iso3: ['lug'], name: messages.lg, nativeName: 'Luganda' },
  { id: 'mh', iso3: ['mah'], name: messages.mh, nativeName: 'Kajin M̧ajeļ' },
  { id: 'ml', iso3: ['mal'], name: messages.ml, nativeName: 'മലയാളം' },
  { id: 'mr', iso3: ['mar'], name: messages.mr, nativeName: 'मराठी' },
  { id: 'mg', iso3: ['mlg'], name: messages.mg, nativeName: 'fiteny malagasy' },
  { id: 'mt', iso3: ['mlt'], name: messages.mt, nativeName: 'Malti' },
  { id: 'mn', iso3: ['mon'], name: messages.mn, nativeName: 'монгол хэл' },
  { id: 'na', iso3: ['nau'], name: messages.na, nativeName: 'dorerin Naoero' },
  { id: 'nv', iso3: ['nav'], name: messages.nv, nativeName: 'Diné bizaad' },
  { id: 'nr', iso3: ['nbl'], name: messages.nr, nativeName: 'isiNdebele seSewula' },
  { id: 'nd', iso3: ['nde'], name: messages.nd, nativeName: 'siNdebele saseNyakatho' },
  { id: 'ng', iso3: ['ndo'], name: messages.ng, nativeName: 'ndonga' },
  { id: 'ne', iso3: ['nep'], name: messages.ne, nativeName: 'नेपाली भाषा' },
  { id: 'nn', iso3: ['nno'], name: messages.nn, nativeName: 'nynorsk' },
  { id: 'nb', iso3: ['nob'], name: messages.nb, nativeName: 'bokmål' },
  { id: 'no', iso3: ['nor'], name: messages.no, nativeName: 'norsk' },
  { id: 'ny', iso3: ['nya'], name: messages.ny, nativeName: 'Chichewa' },
  { id: 'oc', iso3: ['oci'], name: messages.oc, nativeName: 'occitan' },
  { id: 'oj', iso3: ['oji'], name: messages.oj, nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  { id: 'or', iso3: ['ori'], name: messages.or, nativeName: 'ଓଡ଼ିଆ' },
  { id: 'om', iso3: ['orm'], name: messages.om, nativeName: 'Afaan Oromoo' },
  { id: 'os', iso3: ['oss'], name: messages.os, nativeName: 'Ирон æвзаг' },
  { id: 'pa', iso3: ['pan'], name: messages.pa, nativeName: 'ਪੰਜਾਬੀ / پنجابی‎' },
  { id: 'pl', iso3: ['pol'], name: messages.pl, nativeName: 'Język polski' },
  { id: 'pt', iso3: ['por'], name: messages.pt, nativeName: 'português' },
  { id: 'ps', iso3: ['pus'], name: messages.ps, nativeName: 'پښتو' },
  { id: 'qu', iso3: ['que'], name: messages.qu, nativeName: 'Runa simi' },
  { id: 'rm', iso3: ['roh'], name: messages.rm, nativeName: 'Rumantsch' },
  { id: 'rn', iso3: ['run'], name: messages.rn, nativeName: 'Ikirundi' },
  { id: 'ru', iso3: ['rus'], name: messages.ru, nativeName: 'русский язык' },
  { id: 'sg', iso3: ['sag'], name: messages.sg, nativeName: 'yângâ tî sängö' },
  { id: 'si', iso3: ['sin'], name: messages.si, nativeName: 'සිංහල' },
  { id: 'sl', iso3: ['slv'], name: messages.sl, nativeName: 'slovenski jezik' },
  { id: 'se', iso3: ['sme'], name: messages.se, nativeName: 'davvisámegiella' },
  { id: 'sm', iso3: ['smo'], name: messages.sm, nativeName: 'Gagana faʻa Sāmoa' },
  { id: 'sn', iso3: ['sna'], name: messages.sn, nativeName: 'chiShona' },
  { id: 'sd', iso3: ['snd'], name: messages.sd, nativeName: 'سنڌي / सिन्धी / ਸਿੰਧੀ' },
  { id: 'so', iso3: ['som'], name: messages.so, nativeName: 'af Soomaali' },
  { id: 'st', iso3: ['sot'], name: messages.st, nativeName: 'Sesotho [southern]' },
  { id: 'es', iso3: ['spa'], name: messages.es, nativeName: 'español' },
  { id: 'sc', iso3: ['srd'], name: messages.sc, nativeName: 'sardu' },
  { id: 'sr', iso3: ['srp'], name: messages.sr, nativeName: 'српски / srpski' },
  { id: 'ss', iso3: ['ssw'], name: messages.ss, nativeName: 'siSwati' },
  { id: 'su', iso3: ['sun'], name: messages.su, nativeName: 'ᮘᮞ ᮞᮥᮔ᮪ᮓ / Basa Sunda' },
  { id: 'sw', iso3: ['swa'], name: messages.sw, nativeName: 'Kiswahili' },
  { id: 'sv', iso3: ['swe'], name: messages.sv, nativeName: 'svenska' },
  { id: 'ty', iso3: ['tah'], name: messages.ty, nativeName: 'Reo Tahiti' },
  { id: 'ta', iso3: ['tam'], name: messages.ta, nativeName: 'தமிழ்' },
  { id: 'tt', iso3: ['tat'], name: messages.tt, nativeName: 'татар теле / tatar tele / تاتار' },
  { id: 'te', iso3: ['tel'], name: messages.te, nativeName: 'తెలుగు' },
  { id: 'tg', iso3: ['tgk'], name: messages.tg, nativeName: 'тоҷикӣ / tojikī' },
  { id: 'tl', iso3: ['tgl'], name: messages.tl, nativeName: 'Wikang Tagalog' },
  { id: 'th', iso3: ['tha'], name: messages.th, nativeName: 'ภาษาไทย' },
  { id: 'ti', iso3: ['tir'], name: messages.ti, nativeName: 'ትግርኛ' },
  { id: 'to', iso3: ['ton'], name: messages.to, nativeName: 'lea faka-Tonga' },
  { id: 'tn', iso3: ['tsn'], name: messages.tn, nativeName: 'Setswana' },
  { id: 'ts', iso3: ['tso'], name: messages.ts, nativeName: 'Xitsonga' },
  {
    id: 'tk',
    iso3: ['tuk'],
    name: messages.tk,
    nativeName: "'Türkmençe / Түркменче / تورکمن تیلی ",
  },
  { id: 'tr', iso3: ['tur'], name: messages.tr, nativeName: 'Türkçe' },
  { id: 'tw', iso3: ['twi'], name: messages.tw, nativeName: 'Twi' },
  { id: 'ug', iso3: ['uig'], name: messages.ug, nativeName: 'ئۇيغۇرچە ' },
  { id: 'uk', iso3: ['ukr'], name: messages.uk, nativeName: 'українська мова' },
  { id: 'ur', iso3: ['urd'], name: messages.ur, nativeName: 'اُردُو‎' },
  { id: 'uz', iso3: ['uzb'], name: messages.uz, nativeName: 'Oʻzbekcha / ўзбекча / ئوزبېچه' },
  { id: 've', iso3: ['ven'], name: messages.ve, nativeName: 'Tshivenḓa' },
  { id: 'vi', iso3: ['vie'], name: messages.vi, nativeName: 'Tiếng Việt' },
  { id: 'wa', iso3: ['wln'], name: messages.wa, nativeName: 'Walon' },
  { id: 'wo', iso3: ['wol'], name: messages.wo, nativeName: 'Wollof' },
  { id: 'xh', iso3: ['xho'], name: messages.xh, nativeName: 'isiXhosa' },
  { id: 'yi', iso3: ['yid'], name: messages.yi, nativeName: "'ייִדיש" },
  { id: 'yo', iso3: ['yor'], name: messages.yo, nativeName: 'èdè Yorùbá' },
  { id: 'za', iso3: ['zha'], name: messages.za, nativeName: 'Vahcuengh / 話僮' },
  { id: 'zu', iso3: ['zul'], name: messages.zu, nativeName: 'isiZulu' },
];
