import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import './index.less';

interface FooterProps {
  leftElement?: FunctionComponent | JSX.Element;
  rightElement?: FunctionComponent | JSX.Element;
  className?: string;
  fixed?: boolean;
}

function Footer({
  children,
  leftElement,
  rightElement,
  fixed,
  className,
}: PropsWithChildren<FooterProps>) {
  return (
    <footer className={classNames('footer-new', className ?? '', { fixed })}>
      {leftElement && <div className="footer-left">{leftElement}</div>}
      <div className="footer-middle">{children}</div>
      {rightElement && <div className="footer-right">{rightElement}</div>}
    </footer>
  );
}

export default Footer;
