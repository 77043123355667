import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import { useTranslation } from '@getvim/translate';
import Text from '../text';
import { ColorNameEnum, SizeEnum } from '../../types/enums';
import es from './translations/es.json';
import './distanceChip.less';

const messages = defineMessages({
  mile: {
    defaultMessage: 'mi',
    id: 'distanceChip.milesSuffix',
  },
});

const DistanceChipProps = {
  distance: PropTypes.number,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

type DistanceChipPropsType = InferProps<typeof DistanceChipProps>;

const DistanceChip: FunctionComponent<DistanceChipPropsType> = ({
  distance,
  showIcon,
  className,
}) => {
  const intl = useTranslation({ es });
  if (distance == null) return null;
  return (
    <div className={classNames('distance', className)}>
      {showIcon ? (
        <Text
          colorName={ColorNameEnum.themeSecondary}
          size={SizeEnum['16px']}
          className="distance-icon icon-map-pin-e"
        />
      ) : null}
      <span>
        {distance} {intl.formatMessage(messages.mile)}
      </span>
    </div>
  );
};

DistanceChip.propTypes = DistanceChipProps;
DistanceChip.defaultProps = {
  distance: undefined,
  showIcon: true,
  className: '',
};

export default DistanceChip;
