import { GeoCode } from '../../models/Geocode';
import { FiltersType } from '../../components/filters/typesDef';
import { FreeTextTerm } from '../../models/FreeText';

export type FormDefTypes = {
  freeText: FreeTextTerm;
  geo: Partial<GeoCode>;
  filters: FiltersType;
  queryId: string;
};

export const defaultFilterValues: FiltersType = {
  gender: 'ANY',
  distance: 25,
  spokenLanguage: 'ANY',
  sanitasPlus: false,
  offerTelemedicine: false,
};

export const defaultFormValues = ({
  freeText,
  geo,
  queryId,
  filters = defaultFilterValues,
}: Omit<FormDefTypes, 'filters'> & { filters?: FiltersType }): FormDefTypes => ({
  freeText,
  geo,
  queryId,
  filters,
});
