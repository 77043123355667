import { useEffect } from 'react';
import qs from 'qs';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { safeSessionStorage } from '@getvim/utils-safe-storage';
import { v4 as uuid } from 'uuid';
import { assertType, NonEmptyString, NonEmptyStringValidator } from '../models/utils';
import redirectToErrorPage from '../utils/redirectToErrorPage';
import { sendLogToBackend } from './sendLogsToBackend';

const API_KEY = 'apiKey';
const ACCESS_TOKEN = 'accessToken';
const ACCESS_TOKEN_EXPIRY = 'accessTokenExpiry';
const SESSION_ID = 'sessionId';

function apiKeyFromStorage(): unknown {
  return safeSessionStorage.getItem(API_KEY);
}

function apiKeyFromQs(): unknown {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  return query.apiKey;
}

export function getApiKey(): NonEmptyString {
  const apiKey = apiKeyFromStorage() || apiKeyFromQs();

  return apiKey as any;
}

export function getApiKeyUnsafe(): string | null {
  return safeSessionStorage.getItem(API_KEY);
}

export function useApiKeyFromQSOnMount(setReady: (isReady: boolean) => void) {
  const { apiKey } = useQueryString();
  useEffect(() => {
    if (apiKey) {
      try {
        removeApiKey();
        removeAccessToken();

        assertType(apiKey, NonEmptyStringValidator);
        saveApiKey(apiKey);
        setReady(true);
      } catch (error) {
        sendLogToBackend({ location: `tokensStorage:useApiKeyFromQSOnMount:catch`, apiKey, error });
        redirectToErrorPage(getApiKeyUnsafe());
      }
    } else {
      sendLogToBackend({ location: `tokensStorage:useApiKeyFromQSOnMount:else`, apiKey });
    }
  }, [apiKey, setReady]);
}

export function saveApiKey(apiKey: NonEmptyString) {
  safeSessionStorage.setItem(API_KEY, apiKey);
}

function removeApiKey() {
  safeSessionStorage.removeItem(API_KEY);
}

export function saveAccessToken(accessToken: NonEmptyString) {
  safeSessionStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function getAccessToken(): NonEmptyString | undefined {
  const accessToken = safeSessionStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    assertType(accessToken, NonEmptyStringValidator);
    return accessToken;
  }
  return undefined;
}

export function saveAccessTokenExpiry(expiry: NonEmptyString) {
  safeSessionStorage.setItem(ACCESS_TOKEN_EXPIRY, expiry);
}

export function getAccessTokenExpiry(): NonEmptyString | undefined {
  const expiry = safeSessionStorage.getItem(ACCESS_TOKEN_EXPIRY);
  if (expiry) {
    assertType(expiry, NonEmptyStringValidator);
    return expiry;
  }
  return undefined;
}

function removeAccessToken() {
  safeSessionStorage.removeItem(ACCESS_TOKEN);
}

export function getSessionIdIfExists() {
  return safeSessionStorage.getItem(SESSION_ID);
}

export function getSessionId() {
  let sessionId = getSessionIdIfExists();
  if (!sessionId) {
    sessionId = uuid();
    safeSessionStorage.setItem(SESSION_ID, sessionId);
  }
  return sessionId;
}

export function clearSessionId() {
  safeSessionStorage.removeItem(SESSION_ID);
}
