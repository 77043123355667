/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo } from 'react';
import Select, { Props, OptionTypeBase, SelectComponentsConfig } from 'react-select';
import './index.less';
import Loader from '../../atoms/loader';

interface Option {
  label: string;
  value: string;
  data: any;
}

export interface DefaultOptionType extends OptionTypeBase {
  label: string;
  value: string;
}

type ThemeType = {
  mainColor: string;
  secondaryColor: string;
};

type ValueCompareType = (value: any, optionValue: any) => boolean;

export interface SelectInputType<T extends OptionTypeBase = DefaultOptionType>
  extends Omit<Props<T>, 'theme'> {
  id?: string;
  options?: T[];
  onChange: (selectedOptions: T[]) => void;
  selectLabel?: string;
  theme: ThemeType;
  valueCompare?: ValueCompareType | ValueCompareType[];
  name?: string;
  customComponents?: Partial<Props['components']>;
  inputRef?: any;
  firstFetch?: boolean;
  autoFocusValue?: boolean;
  iconClassName?: string;
  isClearable?: boolean;
  displayBodyLoader?: boolean;
  disableOptionsFilter?: boolean;
  filterOption?: (option: Option, rawInput: string) => boolean;
}

const styles = (theme: ThemeType) => ({
  option: (styles: any, state: any) => ({
    ...styles,
    background: state.isFocused || state.isSelected ? theme.secondaryColor : 'white',
    ':active': {
      backgroundColor: theme.secondaryColor,
    },
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    borderBottom: state.menuIsOpen || state.isFocused ? `1px solid ${theme.mainColor}` : null,
    '&:hover': {
      borderBottom: state.menuIsOpen ? `1px solid ${theme.mainColor}` : null,
    },
  }),
  noOptionsMessage: (styles: any, _: any) => ({
    ...styles,
    padding: 'none',
  }),
});

const DefaultLoader = ({ theme }: { theme: ThemeType }) => (
  <Loader type="dots" theme={theme} size="small" color="grey" padding="none" />
);

function findSelectedOption<T extends OptionTypeBase = DefaultOptionType>(
  value: any,
  options: T[],
  valueCompare: ValueCompareType[],
): T[] | null {
  if (!value) return null;
  for (const currCompareFunc of valueCompare) {
    const found = options.filter((option) => currCompareFunc(value, option.value));

    if (found.length) return found;
  }
  return null;
}

function SelectInput<T extends OptionTypeBase = DefaultOptionType>({
  id,
  selectLabel,
  placeholder,
  options,
  value,
  valueCompare = [(value, optionValue) => optionValue === value],
  theme,
  customComponents = {},
  name,
  inputRef,
  onChange,
  firstFetch,
  filterOption,
  autoFocusValue = false,
  iconClassName,
  isClearable = true,
  displayBodyLoader = false,
  disableOptionsFilter = false,
  ...props
}: SelectInputType<T>) {
  const selectedOption = useMemo(
    () =>
      findSelectedOption<T>(
        value,
        options,
        Array.isArray(valueCompare) ? valueCompare : [valueCompare],
      ),
    [value, options],
  );
  useEffect(() => {
    if (firstFetch && selectedOption) {
      onChange(selectedOption);
    }
  }, [selectedOption, firstFetch]);

  const getComponents = useCallback(() => {
    let components: Partial<SelectComponentsConfig<T>> = {};
    if (displayBodyLoader) {
      components.LoadingMessage = () => <DefaultLoader theme={theme} />;
      components.LoadingIndicator = null;
    } else {
      components.LoadingIndicator = () => <DefaultLoader theme={theme} />;
    }
    return { ...components, ...customComponents };
  }, [displayBodyLoader, theme, customComponents]);

  return (
    <div className="select-input">
      <Select
        id={id}
        aria-label={selectLabel || placeholder}
        className={`Select ${iconClassName}`}
        classNamePrefix="Select"
        placeholder={placeholder}
        options={options}
        value={selectedOption?.[0]}
        isClearable={isClearable}
        styles={styles(theme)}
        ref={inputRef}
        autoFocus={autoFocusValue}
        components={getComponents()}
        onChange={(option) => onChange(option ? [option] : null)}
        filterOption={filterOption ?? (disableOptionsFilter ? () => true : undefined)}
        {...props}
      />
      <label className="select-label">{selectLabel}</label>
    </div>
  );
}

export default SelectInput;
