import qs from 'qs';
import Pages from '../models/pages';

function redirectToErrorPage(apiKey?: string | null, analyticsMetadata?: object) {
  const qsOptions = {
    allowDots: true,
    encode: false,
    ignoreQueryPrefix: true,
  };
  const { user } = qs.parse(window.location.search, qsOptions);

  window.location.assign(
    `${Pages.ErrorPage.path}?${qs.stringify({ apiKey, user, analyticsMetadata }, qsOptions)}`,
  );
}

export default redirectToErrorPage;
