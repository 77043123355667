import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { AcceptNewPatients } from '../../molecules';
import { BookingHorizonChip, BookingHorizonEnum } from '../../atoms';

import './Availability.less';

export enum AvailabilitySizeEnum {
  large = 'large',
  small = 'small',
  tiny = 'tiny',
}

const AvailabilityProps = {
  acceptNewPatients: PropTypes.bool,
  bookingHorizon: PropTypes.oneOf(Object.values(BookingHorizonEnum)),
  bookingHorizonClassName: PropTypes.string,
  acceptNewPatientsIcon: PropTypes.element,
  card: PropTypes.bool,
  shortText: PropTypes.bool,
  showBg: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(AvailabilitySizeEnum)),
};

type AvailabilityPropsType = InferProps<typeof AvailabilityProps>;

const AvailabilitySection = ({
  acceptNewPatients,
  acceptNewPatientsIcon,
  bookingHorizon,
  bookingHorizonClassName,
  shortText,
}: AvailabilityPropsType) => (
  <>
    {acceptNewPatients && (
      <AcceptNewPatients
        acceptNewPatients={acceptNewPatients}
        textClass="item-text"
        itemClass="availability-item new-patients"
        acceptNewPatientsIcon={acceptNewPatientsIcon!}
      />
    )}
    {bookingHorizon && (
      <BookingHorizonChip
        bookingHorizon={bookingHorizon}
        className={bookingHorizonClassName}
        shortText={shortText}
      />
    )}
  </>
);

const Availability: FunctionComponent<AvailabilityPropsType> = ({
  acceptNewPatients,
  acceptNewPatientsIcon,
  bookingHorizon,
  bookingHorizonClassName,
  card,
  shortText,
  size,
  showBg,
}) => {
  if (!bookingHorizon && !acceptNewPatients) return null;
  return (
    <div className={classNames('availability-section', size, { 'show-bg': showBg })}>
      {card ? (
        <div className="card">
          <div className="card-body">
            <AvailabilitySection
              acceptNewPatients={acceptNewPatients}
              bookingHorizon={bookingHorizon}
              bookingHorizonClassName={bookingHorizonClassName}
              shortText={shortText}
              acceptNewPatientsIcon={acceptNewPatientsIcon}
            />
          </div>
        </div>
      ) : (
        <AvailabilitySection
          acceptNewPatients={acceptNewPatients}
          bookingHorizon={bookingHorizon}
          bookingHorizonClassName={bookingHorizonClassName}
          shortText={shortText}
          acceptNewPatientsIcon={acceptNewPatientsIcon}
        />
      )}
    </div>
  );
};

Availability.propTypes = AvailabilityProps;
Availability.defaultProps = {
  bookingHorizonClassName: '',
  card: false,
  shortText: false,
  size: undefined,
  showBg: false,
};

export default Availability;
