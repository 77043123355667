import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import './index.less';

const RoundedImageProps = {
  /**
   * The css class name of the root element.
   */
  className: PropTypes.string,
  /**
   * The css class name of the img tag
   */
  imgClassName: PropTypes.string,
  /**
   * The image source.
   */
  src: PropTypes.string.isRequired,
  /**
   * The alternative content for the img tag.
   */
  alt: PropTypes.string,
};

type RoundedImagePropsType = InferProps<typeof RoundedImageProps>;

const RoundedImage: FunctionComponent<RoundedImagePropsType> = ({
  className,
  imgClassName,
  src,
  alt,
}) => (
  <div className={className || undefined}>
    <img className={imgClassName || undefined} src={src} alt={alt || ''} />
  </div>
);

RoundedImage.propTypes = RoundedImageProps;
RoundedImage.defaultProps = {
  className: 'rounded-img',
  imgClassName: '',
  alt: '',
};

export default RoundedImage;
