import { safeLocalStorage } from '@getvim/utils-safe-storage';

const LOCAL_STORAGE_KEY = 'brokerId';

export function getBrokerId(): string | null {
  return safeLocalStorage.getItem(LOCAL_STORAGE_KEY);
}

export function saveBrokerId(id: string) {
  safeLocalStorage.setItem(LOCAL_STORAGE_KEY, id);
}

export function clearBrokerId() {
  safeLocalStorage.removeItem(LOCAL_STORAGE_KEY);
}
