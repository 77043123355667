import React, { FunctionComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.less';

type BaseLayoutPropsType = {
  children: [ReactNode, ReactNode] | [ReactNode, ReactNode, ReactNode];
  scrollable?: boolean;
  className?: string;
};
type BaseLayoutComponent = FunctionComponent<BaseLayoutPropsType>;

const BaseLayout: BaseLayoutComponent = ({ children, className, scrollable }) => (
  <div className={classNames('base-layout', className, { 'un-scrollable': !scrollable })}>
    {children[0]}
    <div className="base-layout-content">{children[1]}</div>
    {children.length > 2 ? children[2] : null}
  </div>
);

BaseLayout.propTypes = {
  className: PropTypes.string,
  scrollable: PropTypes.bool,
};

BaseLayout.defaultProps = {
  className: undefined,
  scrollable: true,
};

export default BaseLayout;
