/* eslint-disable react/jsx-no-bind */
import React, { FunctionComponent } from 'react';
import { useIntl, FormattedMessage } from '@getvim/translate';
import {
  Text,
  AlignEnum,
  ColorNameEnum,
  SizeEnum,
  WeightEnum,
  Container,
  ContainerWidth,
  NoResultsX,
  deprecated,
} from '@getvim/atomic-ui';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { getApiKey } from '../../api/tokensStorage';
import { SearchResultsType } from '../../models/SearchResults';
import { Language } from '../../models/Language';
import { NonEmptyString } from '../../models/utils';
import { OnBook } from '../bookButtonBySdk/BookButtonBySdk';
import ResultCard from '../resultCard/ResultCard';
import { searchActionAnalytics } from '../../api';

import './Pagination.less';
import './SearchResults.less';
import logger from '../../utils/logger';

const maxPages = 10;

export type SearchResultsProps = OnBook & {
  searchResults: SearchResultsType;
  resultsLimit: number;
  pageNumber: number;
  queryId: string;
  language: Language;
  brokerId?: NonEmptyString;
  onChangePage: (p: number) => void;
  onBookClick: (params: { npi: string; address: string; ranking?: number }) => void;
  analyticsMetadata?: object;
  shouldDisplayDoctorProfile?: boolean;
  shouldNavigateToProfileUsingSdkEvent?: boolean;
};

const SearchResults: FunctionComponent<SearchResultsProps> = ({
  searchResults,
  resultsLimit,
  pageNumber,
  queryId,
  language,
  brokerId,
  onChangePage,
  onBook,
  onBookClick,
  analyticsMetadata,
  shouldDisplayDoctorProfile = true,
  shouldNavigateToProfileUsingSdkEvent,
}) => {
  const intl = useIntl();
  let numOfPages = Math.min(Math.ceil(searchResults.total / resultsLimit), maxPages);
  if (numOfPages === 1) numOfPages = 0;

  const [shouldUseBackpackAddressFormat, isShouldUseBackpackAddressFormatLoading] = useFeatureFlag({
    flagName: 'provider_discovery.shouldUseBackpackAddressFormat',
    defaultValue: false,
    flagContext: { apiKey: getApiKey() },
    team: Team.Interfaces,
  });

  return !searchResults.providers.length ? (
    <div className="no-results text-center margin-top-50">
      <Container width={ContainerWidth.small}>
        <Text
          weight={WeightEnum.bold}
          className="margin-top-30"
          size={SizeEnum['18px']}
          colorName={ColorNameEnum.theme}
        >
          <FormattedMessage id="general.sorry" />
        </Text>
        <Text size={SizeEnum['14px']} colorName={ColorNameEnum.extraDark} className="margin-top">
          <FormattedMessage id="components.searchResults.noProvidersFound" />
        </Text>
        <Text size={SizeEnum['14px']} colorName={ColorNameEnum.extraDark}>
          <FormattedMessage id="components.searchResults.tryAgain" />
        </Text>
        <div className="margin-top-30">
          <NoResultsX />
        </div>
      </Container>
    </div>
  ) : (
    <div className="provider-discovery-results padding-box-30">
      <Text align={AlignEnum.left}>
        {searchResults.total === 1
          ? intl.formatMessage({ id: 'components.searchResults.oneSearchResult' })
          : intl.formatMessage(
              { id: 'components.searchResults.totalSearchResults' },
              {
                total: searchResults.total,
              },
            )}
      </Text>
      {!isShouldUseBackpackAddressFormatLoading &&
        searchResults.providers.map((provider) => {
          return (
            <ResultCard
              key={provider.npi}
              provider={provider}
              language={language}
              queryId={queryId}
              brokerId={brokerId}
              analyticsMetadata={analyticsMetadata}
              onBook={({ appointmentId, bookingType, ...payload }) => {
                const [location] = provider.locations;
                searchActionAnalytics({
                  actionType: bookingType!,
                  npi: provider.npi,
                  locationId: location.id,
                  queryId,
                  ranking: provider.ranking!,
                  entityId: appointmentId,
                }).then(() => {
                  if (onBook) {
                    onBook(payload);
                  }
                });
              }}
              shouldDisplayDoctorProfile={shouldDisplayDoctorProfile}
              shouldNavigateToProfileUsingSdkEvent={shouldNavigateToProfileUsingSdkEvent}
              onProviderDetailsClick={() => {
                const [location] = provider.locations;
                searchActionAnalytics({
                  actionType: 'DETAILS',
                  npi: provider.npi,
                  locationId: location.id,
                  queryId,
                  ranking: provider.ranking!,
                }).catch((error) => {
                  logger.error(`Failed to send "details" analytics!`, { e: error });
                });
              }}
              onBookClick={({ npi, address }) =>
                onBookClick({ npi, address, ranking: provider.ranking })
              }
              shouldUseBackpackAddressFormat={shouldUseBackpackAddressFormat}
            />
          );
        })}
      <div className="margin-top-20">
        <deprecated.Pagination
          active={pageNumber}
          maxButtons={3}
          pagesNumber={numOfPages}
          onClick={onChangePage}
          className="vim-pagination"
        />
      </div>
    </div>
  );
};

export default SearchResults;
