import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './index.less';

export type CheckboxInputProps = {
  id?: string;
  value?: boolean;
  label?: any;
  errorText?: string;
  errorClassName?: string;
  disabled?: boolean;
  className?: string;
  name?: string;
  onChange?: any;
  checked?: any;
};

const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({
  id,
  label,
  className,
  name,
  checked,
  value,
  errorText,
  errorClassName,
  disabled,
  onChange,
  ...otherProps
}) => {
  const hasError = !!errorText;

  return (
    <div
      className={classNames('checkbox-container form-group material-form-group', className, {
        'has-error': hasError,
        disabled,
      })}
    >
      <label htmlFor={id}>
        <input
          type="checkbox"
          className="css-checkbox"
          onChange={onChange}
          checked={checked}
          id={id}
          disabled={disabled}
          {...otherProps}
        />
        <span>{label}</span>
        <span className="css-label-checkbox" />
      </label>
      {hasError ? (
        <span className={classNames('validation-message', errorClassName)}>{errorText}</span>
      ) : null}
    </div>
  );
};

export default CheckboxInput;
