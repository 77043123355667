import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { getApiKey } from '../api/tokensStorage';
import Pages, { PageType } from '../models/pages';

function usePageNav() {
  const history = useHistory();

  return async ({ page, params }: { page: PageType; params?: any }) => {
    const apiKey = params?.apiKey || getApiKey();
    history.push(`${Pages[page].path}?${qs.stringify({ ...params, apiKey })}`, {
      allowDots: true,
      encode: false,
    });
  };
}

export default usePageNav;
