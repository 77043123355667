import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { SvgPageNotFound, Button, Text } from '../../atoms';
import { AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '../../types';

import './errorPage.less';

export interface ErrorPageProps {
  title: string;
  className?: string;
  description: string;
  buttonText?: string;
  errorText?: string;
  onSubmit?: () => void;
  displayError?: boolean;
  displayButton?: boolean;
  onClose?: () => void;
  errorIcon?: JSX.Element;
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({
  title,
  description,
  buttonText,
  errorText,
  displayError = false,
  displayButton = true,
  onSubmit,
  errorIcon = <SvgPageNotFound />,
  onClose,
  className,
}) => (
  <div className={classNames('error-page-wrap', className)}>
    {onClose && (
      <Button onClick={onClose} buttonType="link" className="close-button">
        <Text className="icon-x" colorName={ColorNameEnum.ultraDark} size={SizeEnum['18px']} />
      </Button>
    )}
    <Text
      colorName={ColorNameEnum.theme}
      size={SizeEnum['18px']}
      weight={WeightEnum.bold}
      align={AlignEnum.center}
      text={title}
    />
    <Text
      className="margin-top-10 margin-bottom-45"
      size={SizeEnum['14px']}
      colorName={ColorNameEnum.ultraDark}
      align={AlignEnum.center}
      text={description}
    />
    <div className="row">{errorIcon}</div>
    {displayButton ? (
      <div className="row">
        <Button
          className="margin-top-50"
          buttonType="small"
          bgColor="themedOutline"
          onClick={onSubmit}
        >
          {buttonText}
        </Button>
      </div>
    ) : null}
    <div className={classNames('row dev-error', { visible: displayError })}>{errorText}</div>
  </div>
);

export default ErrorPage;
