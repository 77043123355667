import React, { FunctionComponent } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from '@getvim/translate';
import { SvgCalendarMd } from '../svgs';
import es from './translations/es.json';

import './BookingHorizonChip.less';
import {
  BookingHorizonMessagesFullMessages,
  BookingHorizonMessagesShortMessages,
} from './messages';

export enum BookingHorizonEnum {
  SAME_DAY = 'sameDay',
  SAME_WEEK = 'sameWeek',
  SAME_MONTH = 'sameMonth',
  OVER_A_MONTH = 'overAMonth',
  NOT_ACCEPTING = 'notAccepting',
}

const BookingHorizonChipProps = {
  bookingHorizon: PropTypes.oneOf(Object.values(BookingHorizonEnum)).isRequired,
  shortText: PropTypes.bool,
  className: PropTypes.string,
};

type BookingHorizonChipPropsType = InferProps<typeof BookingHorizonChipProps>;

const bookingHorizonToMessage = {
  [BookingHorizonEnum.SAME_DAY]: {
    message: BookingHorizonMessagesShortMessages.sameDay,
    fullMessage: BookingHorizonMessagesFullMessages.sameDay,
  },
  [BookingHorizonEnum.SAME_WEEK]: {
    message: BookingHorizonMessagesShortMessages.sameWeek,
    fullMessage: BookingHorizonMessagesFullMessages.sameWeek,
  },
  [BookingHorizonEnum.SAME_MONTH]: {
    message: BookingHorizonMessagesShortMessages.sameMonth,
    fullMessage: BookingHorizonMessagesFullMessages.sameMonth,
  },
  [BookingHorizonEnum.OVER_A_MONTH]: {
    message: BookingHorizonMessagesShortMessages.overAMonth,
    fullMessage: BookingHorizonMessagesFullMessages.overAMonth,
  },
  [BookingHorizonEnum.NOT_ACCEPTING]: {
    message: BookingHorizonMessagesShortMessages.notAccepting,
    fullMessage: BookingHorizonMessagesFullMessages.notAccepting,
  },
};

const BookingHorizonChip: FunctionComponent<BookingHorizonChipPropsType> = ({
  bookingHorizon,
  shortText,
  className,
}) => {
  const intl = useTranslation({ es });
  if (bookingHorizon === BookingHorizonEnum.NOT_ACCEPTING) return null;
  return (
    <div className="availability-item booking-horizon">
      <SvgCalendarMd />
      <div className={classNames('item-text', className)}>
        {shortText
          ? intl.formatMessage(bookingHorizonToMessage[bookingHorizon].message)
          : intl.formatMessage(bookingHorizonToMessage[bookingHorizon].fullMessage)}
      </div>
    </div>
  );
};

BookingHorizonChip.propTypes = BookingHorizonChipProps;
BookingHorizonChip.defaultProps = {
  shortText: false,
  className: '',
};

export default BookingHorizonChip;
