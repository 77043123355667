import { array, literal, string, type, TypeOf, union, undefined as undefinedType } from 'io-ts';

export const TypeaheadTypesValidator = union(
  [literal('taxonomy'), literal('provider')],
  'TypeaheadTypes',
);
export enum TypeaheadTypes {
  taxonomy = 'taxonomy',
  provider = 'provider',
}

export const FreeTextTermValidator = union([
  type(
    {
      type: TypeaheadTypesValidator,
      value: array(string),
    },
    'FreeTextTerm',
  ),
  undefinedType,
]);

export type FreeTextTerm = TypeOf<typeof FreeTextTermValidator>;

export const FreeTextOptionsValidator = type(
  {
    label: string,
    value: FreeTextTermValidator,
  },
  'FreeTextOption',
);
export type FreeTextOption = TypeOf<typeof FreeTextOptionsValidator>;
