import React, { FunctionComponent } from 'react';
import { useTranslation } from '@getvim/translate';
import { Button, ButtonProps, Popover, usePopoverState } from '../../../atoms';
import { LangEnum, langEnumToName, LanguagesSelector, FilterOptions } from '../../buttonSelectors';
import { PopoverFilterType } from '../typesDef';
import messages from '../messages';
import es from '../translations/es.json';

import '../Filters.less';

export type LanguagePopoverType = PopoverFilterType<LangEnum> & {
  options?: FilterOptions;
  btnType?: ButtonProps['buttonType'];
  btnWidth?: ButtonProps['width'];
  btnBgColor?: ButtonProps['bgColor'];
  displayAny?: boolean;
  nativeLanguageLabels?: boolean;
};

const LanguagePopover: FunctionComponent<LanguagePopoverType> = ({
  theme,
  options,
  value,
  onChange,
  btnType,
  btnWidth,
  btnBgColor,
  displayAny = false,
  nativeLanguageLabels = false,
}) => {
  const intl = useTranslation({ es });
  const languagePopover = usePopoverState({ placement: 'bottom-start' });

  return (
    <Popover
      popover={languagePopover}
      className="language-filter-popover"
      aria-label="Language"
      disclosure={
        <Button
          color="white"
          buttonType={btnType}
          width={btnWidth}
          bgColor={btnBgColor}
          className="language-btn"
          aria-label={intl.formatMessage(messages.languageFilterSelected, {
            language: langEnumToName(value, intl, nativeLanguageLabels),
          })}
        >
          {value === 'ANY'
            ? displayAny
              ? intl.formatMessage(messages.any)
              : intl.formatMessage(messages.languageButtonLabel)
            : langEnumToName(value, intl, nativeLanguageLabels)}
        </Button>
      }
    >
      <div className="popover-content">
        <LanguagesSelector
          nativeLanguageLabels={nativeLanguageLabels}
          filterOptions={options}
          value={value}
          onChange={(filterValue: LangEnum) => {
            onChange(filterValue);
            languagePopover.hide();
          }}
          direction="column"
          className="text-left"
          selectPlaceholder="Enter a language"
          theme={theme}
          buttonType="dropdownOption"
          color="extraDarkGray"
        />
      </div>
    </Popover>
  );
};

export default LanguagePopover;
