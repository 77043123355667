import config from '../config';

const isLogEnabled = () => config.VIM_CLIENT_LOGGER;

const info = (...data: any[]) => {
  if (isLogEnabled()) {
    console.log(...data); // eslint-disable-line
  }
};

const error = (...data: any[]) => {
  if (isLogEnabled()) {
    console.error(...data); // eslint-disable-line
  }
};

const debug = (...data: any[]) => {
  if (isLogEnabled()) {
    console.debug(...data); // eslint-disable-line
  }
};
export default {
  info,
  error,
  debug,
};
