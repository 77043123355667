import React from 'react';
import { defineMessages, IntlShape } from 'react-intl';
import { useTranslation } from '@getvim/translate';
import ButtonGroup, { ButtonGroupPropTypes, ButtonGroupProps } from '../../buttonGroup';
import type { LangEnum } from './languages';
import { languages, messages } from './languages';
import es from '../translations/es.json';

const uiMessages = defineMessages({
  searchLanguagePlaceholder: {
    defaultMessage: 'Search a language',
    id: 'languageSelector.searchLanguagePlaceholder',
  },
});

type OptionTypeTranslated = {
  value: LangEnum;
  text: string;
};

// eslint-disable-next-line no-unused-vars
const { items, ...PropTypes } = ButtonGroupPropTypes;

export type FilterOptions = {
  ids: string[];
};

type LanguagesSelectorProps = Omit<ButtonGroupProps, 'items' | 'value'> & { value: LangEnum } & {
  filterOptions?: FilterOptions;
  nativeLanguageLabels?: boolean;
};

const translateOptions: {
  en?: OptionTypeTranslated[];
  es?: OptionTypeTranslated[];
} = {};

function filterLanguageOptions(allOptions: OptionTypeTranslated[], filterOptions?: FilterOptions) {
  if (!filterOptions) {
    return allOptions;
  }

  return allOptions.filter(
    (option) => filterOptions.ids.includes(option.value) || option.value === 'ANY',
  );
}

const getOptions = (intl: IntlShape, nativeLanguageLabels = false) => {
  const language = intl.locale as 'en' | 'es';

  if (!translateOptions[language]) {
    const commonLanguages: OptionTypeTranslated[] = [];
    const otherLanguages: OptionTypeTranslated[] = [];

    languages.forEach(({ id, name, nativeName }) => {
      const currLang = {
        text: nativeLanguageLabels ? nativeName : intl.formatMessage(name),
        value: id,
      };

      if (['en', 'es'].includes(id)) {
        commonLanguages.push(currLang);
      } else {
        otherLanguages.push(currLang);
      }
    });

    translateOptions[language] = [
      {
        value: 'ANY',
        text: intl.formatMessage(messages.any),
      },
      ...commonLanguages,
      ...otherLanguages.sort((a, b) => a.text.localeCompare(b.text, language)),
    ];
  }

  return translateOptions[language]!;
};

function LanguagesSelector({
  filterOptions,
  nativeLanguageLabels = false,
  ...otherProps
}: Omit<LanguagesSelectorProps, 'searchable' | 'searchPlaceholder'> &
  Partial<Pick<LanguagesSelectorProps, 'searchable' | 'searchPlaceholder'>>) {
  const intl = useTranslation({ es });
  const options = filterLanguageOptions(getOptions(intl, nativeLanguageLabels), filterOptions);
  return (
    <ButtonGroup
      items={options}
      searchable
      searchPlaceholder={intl.formatMessage(uiMessages.searchLanguagePlaceholder)}
      {...otherProps}
    />
  );
}

LanguagesSelector.propTypes = PropTypes;

export { LangEnum };

export function langEnumToName(id: LangEnum, intl, nativeLanguageLabels = false) {
  if (nativeLanguageLabels) {
    return languages.find((lang) => lang.id === id)?.nativeName || intl.formatMessage(messages.any);
  }
  const languageMessage = languages.find((lang) => lang.id === id)?.name || messages.any;
  return intl.formatMessage(languageMessage);
}

export default LanguagesSelector;
