import React, { FunctionComponent } from 'react';
import { useTranslation } from '@getvim/translate';
import { Button, ButtonProps, Popover, usePopoverState } from '../../../atoms';
import { GenderEnum, GenderSelector, getTranslatedGender } from '../../buttonSelectors';
import { PopoverFilterType } from '../typesDef';
import messages from '../messages';
import es from '../translations/es.json';

import '../Filters.less';

export type GenderPopoverType = PopoverFilterType<GenderEnum> & {
  btnType?: ButtonProps['buttonType'];
  btnWidth?: ButtonProps['width'];
  btnBgColor?: ButtonProps['bgColor'];
  displayAny?: boolean;
};

const GenderPopover: FunctionComponent<GenderPopoverType> = ({
  theme,
  value,
  onChange,
  btnType,
  btnWidth,
  btnBgColor,
  displayAny = false,
}) => {
  const intl = useTranslation({ es });
  const genderPopover = usePopoverState({ placement: 'bottom-start' });

  return (
    <Popover
      popover={genderPopover}
      className="gender-filter-popover"
      aria-label="Gender"
      disclosure={
        <Button
          color="white"
          buttonType={btnType}
          width={btnWidth}
          bgColor={btnBgColor}
          className="gender-btn"
          aria-label={intl.formatMessage(messages.genderFilterSelected, {
            gender: getTranslatedGender(value, intl),
          })}
        >
          {value === 'ANY'
            ? displayAny
              ? intl.formatMessage(messages.any)
              : intl.formatMessage(messages.genderButtonLabel)
            : getTranslatedGender(value, intl)}
        </Button>
      }
    >
      <div className="popover-content">
        <GenderSelector
          value={value}
          onChange={(filterValue: GenderEnum) => {
            onChange(filterValue);
            genderPopover.hide();
          }}
          direction="column"
          theme={theme}
          buttonType="dropdownOption"
          color="extraDarkGray"
        />
      </div>
    </Popover>
  );
};

export default GenderPopover;
