import React, { FunctionComponent } from 'react';
import { Button, ButtonProps, Popover, usePopoverState } from '../../../atoms';
import { DistanceSlider } from '../../distanceSlider';
import { PopoverFilterType } from '../typesDef';
import messages from '../messages';
import es from '../translations/es.json';

import '../Filters.less';
import { useTranslation } from '@getvim/translate';

export interface DistancePopoverItemsType {
  id: string;
  value: number;
  text: string;
}

export type DistancePopoverType = PopoverFilterType<number> & {
  isTouched: boolean;
  btnType?: ButtonProps['buttonType'];
  btnWidth?: ButtonProps['width'];
  btnBgColor?: ButtonProps['bgColor'];
  overrideItems?: DistancePopoverItemsType[];
  customSlider?: (onChange: any) => any;
  disabled?: boolean;
};

const DistancePopover: FunctionComponent<DistancePopoverType> = ({
  value,
  onChange,
  isTouched,
  overrideItems,
  customSlider,
  disabled = false,
}) => {
  const intl = useTranslation({ es });
  const distancePopover = usePopoverState({ placement: 'bottom-start' });

  return (
    <Popover
      popover={distancePopover}
      className="distance-filter-popover"
      aria-label="Distance"
      disclosure={
        <Button
          disabled={disabled}
          color="white"
          buttonType="dropdown"
          className="distance-btn"
          aria-label={intl.formatMessage(messages.distanceFilterSelected, {
            distance: value,
          })}
        >
          {!isTouched ? intl.formatMessage(messages.distanceButtonLabel) : `${value} mi`}
        </Button>
      }
    >
      <div className="popover-content">
        {(customSlider && customSlider(onChange)) || (
          <DistanceSlider
            overrideItems={overrideItems}
            className="clean-slider"
            value={value}
            onChange={({ target: { value } }: any) => {
              onChange(Number(value));
            }}
          />
        )}
      </div>
    </Popover>
  );
};

export default DistancePopover;
