import React, { FunctionComponent } from 'react';

const SvgCalendarMd: FunctionComponent = () => (
  <svg
    className="svg-icon svg-calendar-md"
    width="55px"
    height="48px"
    viewBox="0 0 55 48"
    version="1.1"
  >
    <defs>
      <polygon id="path-1" points="0 0 54.9999 0 54.9999 48 0 48" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-3.000000, -4.000000)">
        <g id="Group-15" transform="translate(3.000000, 4.000000)">
          <polygon
            id="Fill-1"
            fill="var(--secondary-color)"
            points="8.907 27.807 18.612 27.807 18.612 18.101 8.907 18.101"
          />
          <polygon
            id="Fill-2"
            fill="var(--secondary-color)"
            points="22.699 27.807 32.404 27.807 32.404 18.101 22.699 18.101"
          />
          <polygon
            id="Fill-3"
            fill="var(--secondary-color)"
            points="36.491 27.807 46.196 27.807 46.196 18.101 36.491 18.101"
          />
          <polygon
            id="Fill-4"
            fill="var(--secondary-color)"
            points="36.491 41.599 46.196 41.599 46.196 31.894 36.491 31.894"
          />
          <polygon
            id="Fill-5"
            fill="var(--secondary-color)"
            points="22.699 41.599 32.404 41.599 32.404 31.894 22.699 31.894"
          />
          <polygon
            id="Fill-6"
            fill="var(--secondary-color)"
            points="8.907 41.599 18.612 41.599 18.612 31.894 8.907 31.894"
          />
          <g id="Group-14">
            <g id="Group-9">
              <g id="Clip-8" />
              <path
                d="M51.2129,45 L3.7869,45 C3.3539,45 2.9999,44.646 2.9999,44.213 L2.9999,14 L51.9999,14 L51.9999,44.213 C51.9999,44.646 51.6469,45 51.2129,45 M3.7869,3 L51.2129,3 C51.6469,3 51.9999,3.354 51.9999,3.788 L51.9999,11 L2.9999,11 L2.9999,3.788 C2.9999,3.354 3.3539,3 3.7869,3 M51.2129,0 L3.7869,0 C1.6989,0 -0.0001,1.699 -0.0001,3.788 L-0.0001,44.213 C-0.0001,46.301 1.6989,48 3.7869,48 L51.2129,48 C53.3009,48 54.9999,46.301 54.9999,44.213 L54.9999,3.788 C54.9999,1.699 53.3009,0 51.2129,0"
                id="Fill-7"
                fill="var(--main-color)"
              />
            </g>
            <path
              d="M13.5,7 C13.5,8.381 12.381,9.5 11,9.5 C9.619,9.5 8.5,8.381 8.5,7 C8.5,5.619 9.619,4.5 11,4.5 C12.381,4.5 13.5,5.619 13.5,7"
              id="Fill-10"
              fill="var(--main-color)"
            />
            <path
              d="M46.5,7 C46.5,8.381 45.381,9.5 44,9.5 C42.619,9.5 41.5,8.381 41.5,7 C41.5,5.619 42.619,4.5 44,4.5 C45.381,4.5 46.5,5.619 46.5,7"
              id="Fill-12"
              fill="var(--main-color)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCalendarMd;
