import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  BkmdModal,
  ModalHeader,
  Text,
  AlignEnum,
  WeightEnum,
  SizeEnum,
  ColorNameEnum,
  CheckboxInput,
  Button,
  deprecated,
} from '@getvim/atomic-ui';
import { useIntl, FormattedMessage } from '@getvim/translate';
import { useTheme } from '@getvim/components-hooks-use-theme';
import { SearchFiltersType, FiltersType } from '../typesDef';

import './FiltersModal.less';

interface FiltersModalType {
  isOpen: boolean;
  onClose: () => void;
  languageOptions?: deprecated.FilterOptions;
  shouldDisableDistanceFilterForVirtualProviders?: boolean;
}

const FiltersModal: FunctionComponent<FiltersModalType & SearchFiltersType> = ({
  userLanguage,
  languageOptions,
  filters,
  isOpen,
  onClose,
  onChange,
  onReset,
  shouldDisableDistanceFilterForVirtualProviders = false,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const [localChanges, setLocalChanges] = useState<Partial<FiltersType>>({});

  useEffect(() => {
    setLocalChanges({});
  }, [filters]);

  const localFilters = {
    ...filters,
    ...localChanges,
  };

  return (
    <BkmdModal
      isOpen={isOpen}
      handleClose={onClose}
      className="fullscreen-modal filters-modal"
      closeOnBackdrop={false}
    >
      <ModalHeader className="margin-13">
        <Button
          onClick={onClose}
          className="close-dialog-btn"
          aria-label="close"
          buttonType="link"
          color="darkGray"
        >
          <i className="icon-x text-18 i-va-fix-3" />
        </Button>
      </ModalHeader>
      <div className="dialog-body">
        <Text
          size={SizeEnum['12px']}
          colorName={ColorNameEnum.extraDark}
          weight={WeightEnum.bold}
          align={AlignEnum.left}
        >
          <FormattedMessage id="general.distance" />
        </Text>
        <deprecated.DistanceSlider
          disabled={shouldDisableDistanceFilterForVirtualProviders}
          className="clean-slider"
          value={localFilters.distance}
          onChange={({ target: { value } }: any) => {
            setLocalChanges({
              ...localChanges,
              distance: Number(value),
            });
          }}
        />
        <Text
          weight={WeightEnum.bold}
          align={AlignEnum.left}
          size={SizeEnum['12px']}
          colorName={ColorNameEnum.extraDark}
          className="margin-top-30 margin-bottom-20"
        >
          <FormattedMessage id="general.gender" />
        </Text>

        <div className="filter-section">
          <deprecated.GenderPopover
            btnType="outline"
            btnWidth="block"
            theme={theme}
            value={localFilters.gender}
            onChange={(selectedGender: deprecated.GenderEnum) =>
              setLocalChanges({
                ...localChanges,
                gender: selectedGender,
              })
            }
          />
        </div>

        <div className="filter-section">
          <Text
            weight={WeightEnum.bold}
            align={AlignEnum.left}
            size={SizeEnum['12px']}
            colorName={ColorNameEnum.extraDark}
            className="margin-top-30 margin-bottom-20"
          >
            <FormattedMessage id="general.language" />
          </Text>
          <deprecated.LanguagePopover
            btnType="outline"
            btnWidth="block"
            theme={theme}
            options={languageOptions}
            value={localFilters.spokenLanguage}
            onChange={(selectedSpokenLanguages: deprecated.LangEnum) =>
              setLocalChanges({
                ...localChanges,
                spokenLanguage: selectedSpokenLanguages,
              })
            }
          />
        </div>

        <div>
          <CheckboxInput
            className="fancy-checkbox nowrap margin-top-30"
            id="offerTelemedicine"
            checked={localFilters.offerTelemedicine}
            label={intl.formatMessage({ id: 'components.filters.offersVirtualVisits' })}
            onChange={(value: any) =>
              setLocalChanges({
                ...localChanges,
                offerTelemedicine: value.currentTarget.checked,
              })
            }
          />
        </div>

        <div>
          <CheckboxInput
            className="fancy-checkbox nowrap margin-top-30"
            id="sanitasPlus"
            checked={localFilters.sanitasPlus}
            label={intl.formatMessage({ id: 'components.filters.medicareLabel' })}
            onChange={(value: any) =>
              setLocalChanges({
                ...localChanges,
                sanitasPlus: value.currentTarget.checked,
              })
            }
          />
        </div>
      </div>
      <div className="dialog-footer fixed-footer">
        <div className="row tiny-gutter">
          <div className="col-xs-6">
            <Button
              onClick={onReset}
              data-id="reset-filters"
              className="left-action-btn"
              buttonType="small"
              width="block"
              bgColor="themedOutline"
            >
              <FormattedMessage id="components.filters.clearFilters" />
            </Button>
          </div>
          <div className="col-xs-6">
            <Button
              onClick={() => {
                onChange(localChanges);
              }}
              data-id="apply-filters"
              buttonType="small"
              width="block"
            >
              <FormattedMessage id="general.apply" />
            </Button>
          </div>
        </div>
      </div>
    </BkmdModal>
  );
};

export default FiltersModal;
