import React, { FunctionComponent } from 'react';

const SvgNoResults: FunctionComponent = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    className="svg-icon svg-no-results-magnifier"
    width="85px"
    height="88.75px"
    viewBox="0 0 68 71"
    version="1.1"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.37017931 0.444662069 60.2726949 0.444662069 60.2726949 70.7586207 0.37017931 70.7586207"
      />
      <polygon
        id="path-3"
        points="0 0.444662069 2.76281379 0.444662069 2.76281379 3.20797241 0 3.20797241"
      />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-526.000000, -348.000000)">
        <g id="Group-23" transform="translate(526.000000, 347.000000)">
          <path
            d="M23.5256276,22.8673241 C23.5256276,24.2887034 22.3736276,25.4407034 20.9522483,25.4407034 C19.530869,25.4407034 18.378869,24.2887034 18.378869,22.8673241 C18.378869,21.4459448 19.530869,20.2939448 20.9522483,20.2939448 C22.3736276,20.2939448 23.5256276,21.4459448 23.5256276,22.8673241"
            id="Fill-1"
            fill="var(--main-color)"
          />
          <path
            d="M38.7937241,22.8673241 C38.7937241,24.2887034 37.6417241,25.4407034 36.2203448,25.4407034 C34.7989655,25.4407034 33.6469655,24.2887034 33.6469655,22.8673241 C33.6469655,21.4459448 34.7989655,20.2939448 36.2203448,20.2939448 C37.6417241,20.2939448 38.7937241,21.4459448 38.7937241,22.8673241"
            id="Fill-3"
            fill="var(--secondary-color)"
          />
          <path
            d="M31.3872828,31.6903034 L25.3516966,31.6903034 C24.6590069,31.6903034 24.0966621,31.1279586 24.0966621,30.435269 C24.0966621,29.7425793 24.6590069,29.1802345 25.3516966,29.1802345 L31.3872828,29.1802345 C32.0799724,29.1802345 32.6423172,29.7425793 32.6423172,30.435269 C32.6423172,31.1279586 32.0799724,31.6903034 31.3872828,31.6903034"
            id="Fill-5"
            fill="var(--secondary-color)"
          />
          <path
            d="M11.9167448,25.4409517 C11.9167448,16.4111586 19.2632276,9.06467586 28.2930207,9.06467586 C37.3240552,9.06467586 44.6705379,16.4111586 44.6705379,25.4409517 C44.6705379,34.4707448 37.3240552,41.8172276 28.2930207,41.8172276 C19.2632276,41.8172276 11.9167448,34.4707448 11.9167448,25.4409517 M46.1589517,25.4409517 C46.1589517,15.5893655 38.1446069,7.57377931 28.2930207,7.57377931 C18.4414345,7.57377931 10.4270897,15.5893655 10.4270897,25.4409517 C10.4270897,35.2925379 18.4414345,43.3068828 28.2930207,43.3068828 C38.1446069,43.3068828 46.1589517,35.2925379 46.1589517,25.4409517"
            id="Fill-7"
            fill="var(--main-color)"
          />
          <g id="Group-11" transform="translate(3.724138, 0.796469)">
            <g id="Clip-10" />
            <path
              d="M57.0714207,68.5243862 C55.2416276,69.8179034 52.7030069,69.3821793 51.4094897,67.5573517 L36.0921103,45.9201103 C38.4991448,44.6104552 40.6405241,42.8861793 42.4678345,40.8726621 L58.0384552,62.8636966 C59.3319724,64.6910069 58.898731,67.2296276 57.0714207,68.5243862 M59.2537655,62.0034207 L43.4721103,39.7119724 C46.7766621,35.5732138 48.7678345,30.3408 48.7678345,24.6441103 C48.7678345,11.3005241 37.9119724,0.444662069 24.5696276,0.444662069 C11.2260414,0.444662069 0.37017931,11.3005241 0.37017931,24.6441103 C0.37017931,30.2874207 2.35762759,35.7817655 5.96755862,40.1154207 C6.23073103,40.4319724 6.70121379,40.4754207 7.01652414,40.2110069 C7.33307586,39.9478345 7.37652414,39.4798345 7.11211034,39.1632828 C3.72562759,35.0952828 1.85983448,29.9385931 1.85983448,24.6441103 C1.85983448,12.1223172 12.0465931,1.93431724 24.5696276,1.93431724 C37.0914207,1.93431724 47.2781793,12.1223172 47.2781793,24.6441103 C47.2781793,37.1659034 37.0914207,47.3539034 24.5696276,47.3539034 C20.6928,47.3539034 16.8544552,46.3471448 13.4654897,44.442869 C13.106731,44.2430069 12.6523862,44.3683862 12.4512828,44.7271448 C12.2501793,45.0859034 12.3768,45.5390069 12.7355586,45.7413517 C16.3454897,47.7710069 20.4383172,48.8435586 24.5696276,48.8435586 C28.2019034,48.8435586 31.6368,48.0155586 34.7303172,46.5755586 L50.1954207,68.4176276 C51.2754207,69.9445241 52.9910069,70.758869 54.7314207,70.758869 C55.8412138,70.7576276 56.9609379,70.4274207 57.9316966,69.7396966 C60.4293517,67.970731 61.022731,64.5010759 59.2537655,62.0034207"
              id="Fill-9"
              fill="var(--main-color)"
            />
          </g>
          <path
            d="M14.2146621,43.1888276 L13.4400414,43.1888276 L13.4400414,42.4142069 C13.4400414,42.002069 13.1061103,41.6681379 12.6939724,41.6681379 C12.2818345,41.6681379 11.9479034,42.002069 11.9479034,42.4142069 L11.9479034,43.1888276 L11.1732828,43.1888276 C10.7611448,43.1888276 10.4272138,43.524 10.4272138,43.9361379 C10.4272138,44.3482759 10.7611448,44.6809655 11.1732828,44.6809655 L11.9479034,44.6809655 L11.9479034,45.4555862 C11.9479034,45.8677241 12.2818345,46.2016552 12.6939724,46.2016552 C13.1061103,46.2016552 13.4400414,45.8677241 13.4400414,45.4555862 L13.4400414,44.6809655 L14.2146621,44.6809655 C14.6268,44.6809655 14.960731,44.3482759 14.960731,43.9361379 C14.960731,43.524 14.6268,43.1888276 14.2146621,43.1888276"
            id="Fill-12"
            fill="var(--secondary-color)"
          />
          <path
            d="M66.3525931,50.6371034 L65.5779724,50.6371034 L65.5779724,49.8624828 C65.5779724,49.4503448 65.2428,49.1164138 64.8306621,49.1164138 C64.4197655,49.1164138 64.0858345,49.4503448 64.0858345,49.8624828 L64.0858345,50.6371034 L63.3112138,50.6371034 C62.8990759,50.6371034 62.5651448,50.9722759 62.5651448,51.3844138 C62.5651448,51.7965517 62.8990759,52.1292414 63.3112138,52.1292414 L64.0858345,52.1292414 L64.0858345,52.9038621 C64.0858345,53.316 64.4197655,53.649931 64.8306621,53.649931 C65.2428,53.649931 65.5779724,53.316 65.5779724,52.9038621 L65.5779724,52.1292414 L66.3525931,52.1292414 C66.764731,52.1292414 67.0986621,51.7965517 67.0986621,51.3844138 C67.0986621,50.9722759 66.764731,50.6371034 66.3525931,50.6371034"
            id="Fill-14"
            fill="var(--secondary-color)"
          />
          <path
            d="M57.9757655,13.0999034 C57.0223862,13.0999034 56.2465241,13.8745241 56.2465241,14.8279034 C56.2465241,15.7800414 57.0223862,16.5559034 57.9757655,16.5559034 C58.9291448,16.5559034 59.7050069,15.7800414 59.7050069,14.8279034 C59.7050069,13.8745241 58.9291448,13.0999034 57.9757655,13.0999034 M57.9757655,18.0455586 C56.2005931,18.0455586 54.7581103,16.6018345 54.7581103,14.8279034 C54.7581103,13.052731 56.2005931,11.6102483 57.9757655,11.6102483 C59.7509379,11.6102483 61.1934207,13.052731 61.1934207,14.8279034 C61.1934207,16.6018345 59.7509379,18.0455586 57.9757655,18.0455586"
            id="Fill-16"
            fill="var(--secondary-color)"
          />
          <path
            d="M36.2800552,54.7600966 C36.2800552,55.5235448 35.6618483,56.1417517 34.8984,56.1417517 C34.1349517,56.1417517 33.5167448,55.5235448 33.5167448,54.7600966 C33.5167448,53.9978897 34.1349517,53.3796828 34.8984,53.3796828 C35.6618483,53.3796828 36.2800552,53.9978897 36.2800552,54.7600966"
            id="Fill-18"
            fill="var(--secondary-color)"
          />
          <g id="Group-22" transform="translate(0.000000, 3.279228)">
            <g id="Clip-21" />
            <path
              d="M2.76281379,1.82631724 C2.76281379,2.58976552 2.1446069,3.20797241 1.38115862,3.20797241 C0.617710345,3.20797241 -0.000496551724,2.58976552 -0.000496551724,1.82631724 C-0.000496551724,1.06286897 0.617710345,0.444662069 1.38115862,0.444662069 C2.1446069,0.444662069 2.76281379,1.06286897 2.76281379,1.82631724"
              id="Fill-20"
              fill="var(--secondary-color)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgNoResults;
