import React from 'react';
import { useTranslation } from '@getvim/translate';
import classNames from 'classnames';
import Footer from '../footer/index';
import footerLogo from '../../../assets/images/logos/vimLogoSm.png';
import es from './translations/es.json';
import messages from './translations/messages';
import './index.less';

interface PoweredByVimFooterProps {
  hovering?: boolean;
}

function PoweredByVimFooter({ hovering }: PoweredByVimFooterProps) {
  const intl = useTranslation({ es } as any);
  const poweredBy = intl.formatMessage(messages.poweredByText);

  return (
    <Footer className={classNames('powered-by-vim-footer', { hovering })}>
      <div>
        <span className="text-12">{`${poweredBy} `}</span>
        <img src={footerLogo} height="10" width="auto" alt={`${poweredBy} VIM`} />
      </div>
    </Footer>
  );
}

export default PoweredByVimFooter;
