import React from 'react';
import { Text, ColorNameEnum } from '@getvim/atomic-ui';

const IconOrError = ({
  errorMessage,
  icon,
  customColor,
}: {
  errorMessage: string | undefined;
  icon: string;
  customColor?: boolean | undefined;
}) => (
  <Text
    colorName={
      !customColor
        ? errorMessage
          ? ColorNameEnum.danger
          : ColorNameEnum.themeSecondary
        : undefined
    }
    className="input-inner-icon"
  >
    <i className={errorMessage ? 'icon-x text-14' : `${icon} i-va-fix-2`} />
  </Text>
);

export default IconOrError;
