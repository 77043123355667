import logger from '../utils/logger';
import featureFlagsClient from '../utils/feature-flag.client';

// eslint-disable-next-line import/prefer-default-export
export async function sendLogToBackend(params: object) {
  const shouldSendLogToBackend = await featureFlagsClient.getFlag({
    flagName: 'scheduling.should_send_logs_to_backend_on_provider_discovery',
    defaultValue: false,
  });

  if (shouldSendLogToBackend) {
    const currentFullUrl = window.location.href;
    fetch('/log', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ currentFullUrl, params }),
    }).catch((e) => {
      logger.error(`Failed to send log to backend!`, { e });
    });
  }
}
