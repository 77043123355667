interface Config {
  VIM_PUBLIC_API_URL: string;
  VIM_SEGMENT_API_KEY: string;
  VIM_GOOGLE_API_KEY: string;
  VIM_CLIENT_LOGGER: boolean;
  VIM_BOOKING_SDK_ENV: 'local' | 'staging' | 'sandbox' | 'prod' | 'dev' | 'localui';
  rollout__key: string;
}

const defaults: Partial<Config> = {
  VIM_CLIENT_LOGGER: false,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default { ...defaults, ...window.$vim_environment } as Config;
