import React from 'react';

export default function SvgPageNotFound() {
  /* eslint-disable */
  return (
    <svg
      className="svg-icon svg-page-not-found"
      width="390px"
      height="192px"
      viewBox="0 0 390 192"
      version="1.1"
    >
      <defs>
        <polygon
          id="path-1"
          points="-9.09494702e-14 145.2888 208.9456 145.2888 208.9456 0 -9.09494702e-14 0"
        ></polygon>
        <path
          d="M329.0928,61.3264 C329.0928,65.2016 325.9512,68.344 322.0752,68.344 C318.1992,68.344 315.0576,65.2016 315.0576,61.3264 C315.0576,57.4504 318.1992,54.308 322.0752,54.308 C325.9512,54.308 329.0928,57.4504 329.0928,61.3264"
          id="path-3"
        ></path>
        <path
          d="M51.1616,64.2728 C51.1616,68.1488 48.02,71.2904 44.144,71.2904 C40.268,71.2904 37.1264,68.1488 37.1264,64.2728 C37.1264,60.3968 40.268,57.2552 44.144,57.2552 C48.02,57.2552 51.1616,60.3968 51.1616,64.2728"
          id="path-4"
        ></path>
        <polyline
          id="path-5"
          points="98.0344 74.3512 106.6688 64.5832 91.38 22.7768 82.7456 32.5456 98.0344 74.3512"
        ></polyline>
        <path
          d="M72.4912,26.7856 C67.1304,27.9232 62.1608,30.0144 57.8144,32.9704 L48.0904,39.5736 L39.456,49.3416 L49.18,42.7384 C53.5264,39.7832 58.4952,37.692 63.8568,36.5544 L82.7456,32.5456 L91.38,22.7768 L72.4912,26.7856"
          id="path-6"
        ></path>
        <path
          d="M63.8568,36.5544 C58.496,37.692 53.5264,39.7832 49.18,42.7384 L39.456,49.3416 C37.0312,50.992 36.1616,54.1296 37.2856,57.2032 L42.3856,71.1504 C43.5096,74.224 46.384,76.5664 49.5792,77.0248 L62.3848,78.8464 C68.1112,79.6648 73.784,79.4976 79.1448,78.36 L98.0344,74.3512 L82.7456,32.5456 L63.8568,36.5544"
          id="path-7"
        ></path>
        <path
          d="M96.9648,61.5792 C97.452,62.9216 98.0968,63.6968 98.5576,63.5824 C98.6248,63.5672 98.692,63.5488 98.76,63.5328 C103.7408,62.2952 123.1712,57.6312 127.14,56.7136 C129.5416,56.1592 133.58,55.0744 133.0896,51.8328 C132.7576,49.64 130.1624,49.0664 128.3296,49.4656 C125.5528,50.0696 101.304,56.0608 96.9584,57.14 C96.8512,57.1664 96.7544,57.2416 96.6712,57.3688 C96.2328,58.0432 96.3648,59.928 96.9648,61.5792"
          id="path-8"
        ></path>
        <path
          d="M89.0728,39.18 C89.56,40.5216 90.2048,41.2976 90.6656,41.1832 C90.7328,41.1672 90.8008,41.1496 90.868,41.1328 C95.8488,39.8952 115.2792,35.2312 119.248,34.3144 C121.6496,33.7592 125.6888,32.6744 125.1976,29.4328 C124.8664,27.24 122.2704,26.6672 120.4376,27.0656 C117.6608,27.6696 93.412,33.6608 89.0664,34.74 C88.9592,34.7664 88.8624,34.8416 88.78,34.9688 C88.3416,35.6432 88.4728,37.528 89.0728,39.18"
          id="path-9"
        ></path>
        <polyline
          id="path-10"
          points="299.8592 72.084 291.2248 62.3152 306.5128 20.5096 315.1472 30.2776 299.8592 72.084"
        ></polyline>
        <path
          d="M325.4016,24.5176 C330.7624,25.6552 335.732,27.7464 340.0784,30.7024 L349.8032,37.3056 L358.4376,47.0744 L348.7128,40.4712 C344.3664,37.5152 339.3976,35.424 334.036,34.2864 L315.1472,30.2776 L306.5128,20.5096 L325.4016,24.5176"
          id="path-11"
        ></path>
        <path
          d="M334.036,34.2864 C339.3976,35.424 344.3664,37.5152 348.7128,40.4712 L358.4376,47.0744 C360.8616,48.7248 361.7312,51.8624 360.6072,54.936 L355.5072,68.8824 C354.3832,71.956 351.5088,74.2992 348.3136,74.7568 L335.508,76.5784 C329.7816,77.3968 324.1088,77.2296 318.748,76.092 L299.8592,72.084 L315.1472,30.2776 L334.036,34.2864"
          id="path-12"
        ></path>
        <path
          d="M301.6552,55.2248 C301.2568,57.6328 299.7832,59.8016 298.3632,60.0704 C296.9424,60.3392 296.1144,58.6056 296.5128,56.1984 C296.9104,53.7912 298.3848,51.6216 299.8048,51.3528 C301.2248,51.084 302.0536,52.8176 301.6552,55.2248"
          id="path-13"
        ></path>
        <path
          d="M309.4856,36.5336 C309.0872,38.9416 307.6136,41.1104 306.1936,41.3792 C304.7736,41.648 303.9448,39.9144 304.3432,37.5072 C304.7408,35.1 306.2152,32.9304 307.6352,32.6616 C309.0552,32.3928 309.884,34.1264 309.4856,36.5336"
          id="path-14"
        ></path>
        <path
          d="M311.6744,25.0504 C311.6744,25.0504 314.5848,25.7024 318.9512,26.6808 C321.1296,27.1896 323.6712,27.7824 326.3936,28.4176 C327.7536,28.7392 329.1592,29.072 330.5864,29.4096 C332.0232,29.7504 333.468,30.1512 334.8848,30.6392 C337.7192,31.6128 340.4544,32.8704 342.8832,34.28 C344.1032,34.9752 345.2248,35.7472 346.284,36.4632 C347.3456,37.1808 348.3496,37.8592 349.2768,38.4856 C352.9664,41.016 355.4272,42.7024 355.4272,42.7024 C355.4272,42.7024 352.912,41.0992 349.1392,38.6936 C348.2,38.0864 347.1824,37.428 346.1064,36.732 C345.032,36.0296 343.8984,35.3072 342.6888,34.6176 C340.272,33.2368 337.5544,32.0088 334.7408,31.0632 C333.3336,30.592 331.908,30.1696 330.4896,29.828 C329.0632,29.48 327.6592,29.1368 326.3,28.8056 C323.5888,28.1216 321.0584,27.484 318.8888,26.9368 C314.56,25.8048 311.6744,25.0504 311.6744,25.0504"
          id="path-15"
        ></path>
      </defs>
      <g id="web" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-445.000000, -308.000000)">
          <g id="2706574" transform="translate(447.000000, 309.000000)">
            <path
              d="M24.0024,177.104 C-17.7848,178.1192 -6.5632,143.436 70.8088,148.8112 M339.3824,55.712 C371.3392,68.3984 386.7744,81.1752 385.9328,105.6544 C385.1568,128.204 368.8,170.125002 292.4128,163.7104 C246.878815,159.886691 261.6712,189.0376 321.74,189.0376 C383.0992,189.0376 404.392,141.4248 269.596,144.38"
              id="Stroke-44"
              stroke="var(--main-color)"
              strokeWidth="2.4"
            ></path>
            <path
              d="M84.1656,159.2976 C75.5272,159.2976 68.5248,152.2952 68.5248,143.6568 L68.5248,15.7472 C68.5248,7.1088 75.5272,0.1064 84.1656,0.1064 L293.8072,0.1064 C302.4456,0.1064 309.448,7.1088 309.448,15.7472 L309.448,143.6568 C309.448,152.2952 302.4456,159.2976 293.8072,159.2976 L84.1656,159.2976 Z"
              id="Fill-46"
              stroke="var(--main-color)"
              strokeWidth="0.8"
              fill="#FFFFFF"
            ></path>
            <g id="Combined-Shape" transform="translate(84.800000, 7.200000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use
                id="Mask"
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-1"
              ></use>
              <path
                d="M143.30164,34.7455017 C145.805312,37.2841066 145.830566,41.3593346 143.388809,43.9290315 L143.266734,44.0541828 L114.485,72.799 L143.266734,101.545817 L143.388809,101.670968 C145.830566,104.240665 145.805312,108.315893 143.30164,110.854498 C140.756925,113.43472 136.615498,113.450447 134.051504,110.889625 L134.051504,110.889625 L105.2,82.073 L76.3484956,110.889625 C73.784502,113.450447 69.6430751,113.43472 67.0983597,110.854498 C64.594688,108.315893 64.5694342,104.240665 67.0111905,101.670968 L67.1332658,101.545817 L95.914,72.799 L67.1332658,44.0541828 L67.0111905,43.9290315 C64.5694342,41.3593346 64.594688,37.2841066 67.0983597,34.7455017 C69.6430751,32.1652803 73.784502,32.1495534 76.3484956,34.7103746 L76.3484956,34.7103746 L105.2,63.526 L134.051504,34.7103746 C136.615498,32.1495534 140.756925,32.1652803 143.30164,34.7455017 Z"
                fill="var(--main-color)"
                fill-rule="nonzero"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M79.4736,79.7024 C79.4736,81.8616 77.7224,83.612 75.5632,83.612 C73.4032,83.612 71.6528,81.8616 71.6528,79.7024 C71.6528,77.5424 73.4032,75.792 75.5632,75.792 C77.7224,75.792 79.4736,77.5424 79.4736,79.7024"
              id="Fill-48"
              stroke="var(--main-color)"
              strokeWidth="0.8"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <path
              d="M301.28,96.7336 L301.28,96.7336 C299.0816,96.7336 297.2824,94.9344 297.2824,92.736 L297.2824,66.668 C297.2824,64.4696 299.0816,62.6704 301.28,62.6704 C303.4784,62.6704 305.2768,64.4696 305.2768,66.668 L305.2768,92.736 C305.2768,94.9344 303.4784,96.7336 301.28,96.7336"
              id="Fill-49"
              stroke="var(--main-color)"
              strokeWidth="0.8"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <g id="Fill-65">
              <use fill="#FFFFFF" xlinkHref="#path-3"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-3"
              ></use>
            </g>
            <path
              d="M15.748,120.8768 C24.6792,148.204 83.4936,175.6584 24.0024,177.104 M56.0216,57.9792 C22.992,72.3712 7.788,96.5224 15.748,120.8768"
              id="Stroke-66"
              stroke="var(--main-color)"
              strokeWidth="2.4"
            ></path>
            <g id="Fill-67">
              <use fill="#FFFFFF" xlinkHref="#path-4"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-4"
              ></use>
            </g>
            <g id="Fill-89" strokeLinecap="round" stroke-linejoin="round">
              <use fill="#FFFFFF" xlinkHref="#path-5"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-5"
              ></use>
            </g>
            <g id="Fill-90" strokeLinecap="round" stroke-linejoin="round">
              <use fill="#FFFFFF" xlinkHref="#path-6"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-6"
              ></use>
            </g>
            <g id="Fill-91" strokeLinecap="round" stroke-linejoin="round">
              <use fill="#FFFFFF" xlinkHref="#path-7"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-7"
              ></use>
            </g>
            <path
              d="M69.4936,75.1705163 C67.7304,75.1744 65.9672,75.0752 64.2144,74.8784 C62.4632,74.6664 60.7208,74.4008 58.9728,74.1656 L53.7344,73.4368 L51.1144,73.072 C50.6808,73.0056 50.2368,72.9648 49.8048,72.872 C49.3648,72.7712 48.9488,72.604 48.5584,72.3856 C47.78,71.9488 47.112,71.3144 46.644,70.5512 C46.4048,70.1728 46.2432,69.7456 46.0968,69.3344 L45.6384,68.0944 L41.9736,58.1704 L41.06,55.6848 C40.7744,54.8448 40.7376,53.7992 41.3544,53.0432 C41.6736,52.6688 42.0792,52.484 42.4288,52.2184 L45.704,49.98 L50.0704,46.9944 C51.5152,45.9944 52.988,44.9944 54.552,44.1568 C56.1096,43.312 57.7296,42.5832 59.392,41.968 C61.0568,41.3592 62.7616,40.8616 64.4928,40.4808 L69.6664,39.3752 L80.0216,37.212 L80.1816,37.1792 L80.2368,37.3312 L92.5128,71.232 L92.5424,71.312 L92.4592,71.3296 L81.0416,73.7144 C79.1376,74.1024 77.2424,74.5544 75.3104,74.7968 C73.3832,75.0544 71.4376,75.1744 69.4936,75.1705163 L69.4936,75.1705163 Z"
              id="Fill-92"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <g id="Fill-93">
              <use fill="#FFFFFF" xlinkHref="#path-8"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-8"
              ></use>
            </g>
            <g id="Fill-94">
              <use fill="#FFFFFF" xlinkHref="#path-9"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-9"
              ></use>
            </g>
            <path
              d="M98.5576,63.5824 C98.6248,63.5672 98.692,63.5488 98.76,63.5328 C103.7408,62.2952 123.1712,57.6312 127.14,56.7136 C129.5416,56.1592 133.58,55.0744 133.0896,51.8328 C108.6064,57.2296 96.5648,60.4784 96.9648,61.5792 C97.452,62.9216 98.0968,63.6968 98.5576,63.5824 Z"
              id="Fill-93-Copy"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <path
              d="M90.6656,41.1832 C90.7328,41.1672 90.8008,41.1496 90.868,41.1328 C95.8488,39.8952 115.2792,35.2312 119.248,34.3144 C121.6496,33.7592 125.6888,32.6744 125.1976,29.4328 C100.7144,34.8296 88.6728,38.0786667 89.0728,39.18 C89.56,40.5216 90.2048,41.2976 90.6656,41.1832 Z"
              id="Fill-94-Copy"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <g id="Fill-95" strokeLinecap="round" stroke-linejoin="round">
              <use fill="#FFFFFF" xlinkHref="#path-10"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-10"
              ></use>
            </g>
            <g id="Fill-96" strokeLinecap="round" stroke-linejoin="round">
              <use fill="#FFFFFF" xlinkHref="#path-11"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-11"
              ></use>
            </g>
            <g id="Fill-97" strokeLinecap="round" stroke-linejoin="round">
              <use fill="#FFFFFF" xlinkHref="#path-12"></use>
              <use
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-12"
              ></use>
            </g>
            <path
              d="M328.3992,72.902565 C326.4552,72.9064 324.5104,72.7872 322.5824,72.5288 C320.6504,72.2864 318.7552,71.8344 316.8512,71.4464 L305.4336,69.0616 L305.3512,69.044 L305.38,68.9648 L317.6568,35.064 L317.712,34.9128 L317.8712,34.9456 L328.2264,37.1096 L333.4016,38.212 C335.128,38.592 336.8384,39.0904 338.5016,39.6992 C340.164,40.3144 341.784,41.0432 343.3416,41.888 C344.9048,42.7248 346.3776,43.7248 347.8232,44.7264 L355.4648,49.9504 C355.8192,50.2192 356.212,50.3936 356.54,50.7744 C357.1568,51.5312 357.1192,52.5768 356.8336,53.4168 L355.92,55.9024 L351.7968,67.0672 C351.6496,67.4784 351.4896,67.9024 351.2496,68.284 C350.7816,69.0472 350.1136,69.6816 349.3344,70.1184 C348.9448,70.336 348.5264,70.5032 348.0904,70.604 C347.6496,70.6992 347.2152,70.7376 346.7784,70.8048 L344.1584,71.1688 L338.92,71.8976 C337.1728,72.1336 335.4296,72.3992 333.6784,72.6104 C331.9256,72.8072 330.1624,72.9072 328.3992,72.902565 L328.3992,72.902565 Z"
              id="Fill-98"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <g id="Fill-107">
              <use fill="#FFFFFF" xlinkHref="#path-13"></use>
              <use stroke="var(--main-color)" strokeWidth="0.8" fill="var(--main-color)" xlinkHref="#path-13"></use>
            </g>
            <g id="Fill-108">
              <use fill="#FFFFFF" xlinkHref="#path-14"></use>
              <use stroke="var(--main-color)" strokeWidth="0.8" fill="var(--main-color)" xlinkHref="#path-14"></use>
            </g>
            <path
              d="M87.0792,27.3184 C87.0792,27.3184 84.1928,28.0728 79.864,29.2048 C77.6952,29.7512 75.1648,30.3896 72.4536,31.0728 C71.0944,31.4048 69.6904,31.7472 68.2632,32.0952 C66.8448,32.4376 65.4192,32.8592 64.0128,33.3312 C61.1984,34.2768 58.4808,35.504 56.0648,36.8856 C54.8544,37.5744 53.7216,38.2968 52.6472,39 C51.5712,39.696 50.5536,40.3536 49.6144,40.9616 C45.8416,43.3664 43.3264,44.9704 43.3264,44.9704 C43.3264,44.9704 45.7864,43.2832 49.4768,40.7536 C50.4032,40.1272 51.4072,39.4488 52.4688,38.7312 C53.5288,38.0152 54.6504,37.2432 55.8704,36.548 C58.2992,35.1376 61.0336,33.8808 63.8688,32.9072 C65.2856,32.4184 66.7296,32.0184 68.1664,31.6768 C69.5944,31.3392 70.9992,31.0072 72.3592,30.6856 C75.0824,30.0504 77.624,29.4568 79.8024,28.9488 C84.168,27.9704 87.0792,27.3184 87.0792,27.3184"
              id="Fill-110"
              stroke-opacity="0.15"
              stroke="var(--main-color)"
              strokeWidth="0.8"
              fillOpacity="0.15"
              fill="var(--main-color)"
            ></path>
            <g id="Fill-111">
              <use fill="#FFFFFF" xlinkHref="#path-15"></use>
              <use
                stroke-opacity="0.15"
                stroke="var(--main-color)"
                strokeWidth="0.8"
                fillOpacity="0.15"
                fill="var(--main-color)"
                xlinkHref="#path-15"
              ></use>
            </g>
            <line
              x1="189.518382"
              y1="177.50064"
              x2="251.621324"
              y2="177.50064"
              id="Line-2-Copy-4"
              stroke="var(--main-color)"
              strokeLinecap="round"
            ></line>
            <line
              x1="252"
              y1="189"
              x2="314.102941"
              y2="189"
              id="Line-2-Copy-3"
              stroke="var(--main-color)"
              strokeLinecap="round"
            ></line>
            <line
              x1="28"
              y1="177.50064"
              x2="60"
              y2="177.50064"
              id="Line-2-Copy-2"
              stroke="var(--main-color)"
              strokeLinecap="round"
            ></line>
            <line
              x1="74"
              y1="167.50064"
              x2="136"
              y2="167.50064"
              id="Line-2-Copy"
              stroke="var(--main-color)"
              strokeLinecap="round"
            ></line>
            <line
              x1="93.380531"
              y1="187.50064"
              x2="178.619469"
              y2="187.50064"
              id="Line-2"
              stroke="var(--main-color)"
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
