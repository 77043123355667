import es from './es.json';
import en from './en.json';

const messages: {
  es: Record<string, string>;
  en: Record<string, string>;
} = {
  es,
  en,
};

export default messages;
